export * from './feeds';
export * from './tasks';
export * from './filledColors';

export const baseUrl = process.env.REACT_APP_SITE_HOST;
export const setsUrl = `${baseUrl}collections`;
export const privateSetsUrl = `${baseUrl}private-collections`;
export const villagesUrl = `${baseUrl}villages`;
export const presentationBaseUrl =
  process.env.REACT_APP_PRESENTATION_HOST || 'https://presentation.villagio-realty.ru/';
export const feedsBaseUrl = process.env.REACT_APP_FEEDS_HOST || 'https://feed.villagio-realty.ru/';

export const urlsPresentationByType = {
  sets: 'sets',
  objects: 'objects',
  villages: 'groups',
};

export const urlsObjectByType = {
  100: 'plots',
  200: 'houses',
  300: 'townhouses',
  400: 'flats',
  500: 'apartments',
  600: 'penthouses',
  700: 'commercial',
};

export const booleans = {
  true: 'Да',
  false: 'Нет',
};

export const touchDirections = {
  true: 'Входящие',
  false: 'Исходящие',
};

export const initialSales = {
  true: 'Первичка',
  false: 'Вторичка',
};

export const visitShowStatuses = {
  true: 'Вторичный',
  false: 'Первичный',
};

export const groupStatuses = {
  100: 'Готов',
  200: 'Строится',
};

export const luxuryClasses = {
  100: 'Deluxe',
  200: 'Бизнес',
  300: 'Комфорт',
};

export const notificationTargets = {
  100: 'DubaiTelegramBot',
};

export const currencyTypesById = {
  100: 'RUB',
  200: 'USD',
  300: 'EUR',
};

export const publicationStatuses = {
  100: 'Не опубликован',
  200: 'Опубликован',
};

export const filtersPublicationStatuses = {
  0: 'Все',
  100: 'Удалённые',
  200: 'Опубликованные',
  300: 'Не опубликованные',
  400: 'Опубликованные и не опубликованные',
};

export const objectContractTypes = {
  100: 'Эксклюзив',
  200: 'C договором',
  300: 'Без договора',
};

export const currencyTypes = {
  100: 'Рубли',
  200: 'Доллары',
  300: 'Евро',
};

export const expoTypes = {
  100: 'Типовая',
  // 200: 'Без фото',
  300: 'Только для клиентов',
  400: 'Скоро в продаже',
};

export const roles = {
  'back-reader': 'ХО: Читатель',
  'back-admin': 'ХО: Полный доступ',
  'users-admin': 'Auth: Полный доступ',
  'users-read': 'Auth: Читатель',
  'proxy-cityflats-writer': 'Прокси пользователь',
  'site-main': 'Site: Клиент',
  'realty-admin': 'Администратор',
  'realty-expert': 'Эксперт',
  'realty-supervisor': 'Супервайзер',
  'realty-manager': 'Менеджер',
  'realty-representative': 'Представитель',
  'realty-owner': 'Собственник',
  'realty-client': 'Клиент',
  'realty-odp': 'ОДП',
  'realty-editor': 'Редактор',
};

export const roleFlags = {
  isExpert: 'realty-expert',
  isManager: 'realty-manager',
  isSupervisor: 'realty-supervisor',
  isAdmin: 'realty-admin',
  isRepresentative: 'realty-representative',
  isOwner: 'realty-owner',
  isClient: 'realty-client',
  isOdp: 'realty-odp',
  isEditor: 'realty-editor',
};

export const imageSeasons = {
  100: 'Зима',
  200: 'Весна',
  300: 'Лето',
  400: 'Осень',
};

export const imageTypes = {
  100: 'Интерьер',
  200: 'Экстерьер',
  300: 'Коптерэкстерьер',
  400: 'Схема',
  500: 'Фича',
  1000: 'От жильцов',
  1100: 'Из социальных сетей',
  1200: 'Документы',
  1300: '3D-панорама',
};

export const soldWith = {
  100: 'C Villagio',
  200: 'Самостоятельно',
  300: 'Другое',
};

export const changeHistoryActions = {
  '-100': 'Удаление',
  100: 'Создание',
  200: 'Изменение',
};

export const changeHistoryTypes = {
  1400: 'Застройщик',
  2800: 'Скидки',
  2700: 'Свободные справочнки',
  1100: 'Гео-тег',
  1200: 'Фишка группы объектов',
  1300: 'Тип группы объектов',
  100: 'Инфраструктура',
  2600: 'Типы инфраструктуры',
  1000: 'Маркетинговая фишка',
  3000: 'Преимущества объектов',
  2900: 'Виды оплаты',
  200: 'Группа объектов',
  300: 'Объект недвижимости',
  2100: 'Коммуникации объекта недвижимости',
  3400: 'Избранное',
  3500: 'Папка избранного',
  3600: 'Крутота недвиги',
  2400: 'Материалы объектов недвижимости',
  3300: 'Подборка объектов недвижимости',
  3200: 'тип подборки объектов недвижимости',
  2300: 'Состяния объектов недвижимости',
  2500: 'Архитектурные стили объектов недвижимости',
  2200: 'Типы объктов недвижимости',
  1500: 'Тег ландшафта',
  3100: 'Пользователи',
  3700: 'Площадка фидов',
  3800: 'Фид',
  3900: 'Сектор',
  4000: 'Баннеры',
  4100: 'Контакты сайта',
  4200: 'Причины ликвидности',
  4300: 'Тег клиента',
  4400: 'Тег лида',
};

export const sortOrders = {
  100: 'Без сортировки',
  200: 'По имени и порядку ▲',
  201: 'По имени и порядку ▼',
  300: 'По порядку и имени ▲',
  301: 'По порядку и имени ▼',
  400: 'По дате создания ▲',
  401: 'По дате создания ▼',
  500: 'По дате изменения ▲',
  501: 'По дате изменения ▼',
};

export const objectSortOrders = {
  ...sortOrders,
  1000: 'По цене ▲',
  1001: 'По цене ▼',
  1100: 'По площади участка ▲',
  1101: 'По площади участка ▼',
  1200: 'По площади объекта ▲',
  1201: 'По площади объекта ▼',
  1300: 'По дате публикации ▲',
  1301: 'По дате публикации ▼',
  1400: 'По типу лида ▲',
  1401: 'По типу лида ▼',
};

export const clientSortOrders = {
  ...sortOrders,
  1500: 'Необработанные ▲',
};

export const taskSortOrders = {
  ...sortOrders,
  1600: 'По плановой дате выполнения ▲',
  1601: 'По плановой дате выполнения ▼',
};

export const demandSourceStatuses = {
  100: 'Сайт',
  200: 'Вручную',
  300: 'Звонок',
  400: 'Чат',
  500: 'Соцсети',
  600: 'Другое',
};

export const demandTypes = {
  100: 'Покупка',
  200: 'Продажа',
  300: 'Аренда',
  400: 'Сдача',
};

export const demandStatuses = {
  100: 'Новая',
  200: 'Обрабатывается',
  300: 'Отложена',
  400: 'Передана экспертам',
  500: 'Закрыта',
};

export const demandMarkTypes = {
  100: 'Целевая',
  200: 'Нецелевая',
  300: 'Дубль',
};
export const commentTypes = {
  100: 'Недвижимость',
  200: 'Сектор',
  300: 'Человек',
  400: 'Задача',
};

export const ownerStatuses = {
  100: 'Не беспокоить',
  200: 'В работе',
  300: 'Отказался',
  400: 'Отложенный интерес',
  500: 'Не продает, не покупает',
};

export const clientTouchStatuses = {
  true: 'Целевой',
  false: 'Нецелевой',
};

export const clientTouchTargetTypes = {
  100: 'Безусловно целевой',
  200: 'Целевой - Отложенный интерес',
  300: 'Целевой - Отказ',
  400: 'Целевой - Прогрев',
};

export const clientTouchNotTargetTypes = {
  1000: 'Нецелевой: Маленький бюджет',
  1100: 'Нецелевой: Повторный',
  1200: 'Нецелевой: Реклама',
  1300: 'Нецелевой: Обратился по ошибке',
  1400: 'Нецелевой: Корп. номер',
  1500: 'Нецелевой: Вне МО',
  1600: 'Нецелевой: Только Мск',
  1700: 'Нецелевой: Некорректный номер',
  1800: 'Нецелевой: Сбор инфо',
  1900: 'Нецелевой: Не говорит англ|рус',
  10000: 'Нецелевой: Другое',
};

export const clientTouchTypes = {
  ...clientTouchTargetTypes,
  ...clientTouchNotTargetTypes,
};

export const demandCallTechSources = {
  100: 'Calltouch',
  200: 'Chat2Desk',
  300: 'Comagic',
  400: 'Site',
};

export const touchesTypes = {
  100: 'Звонок (CallTouch)',
  200: 'Чат',
  300: 'Звонок',
  400: 'Заявка с сайта',
  500: 'Звонок (Asterisk)',
  600: 'Таймпад',
  700: 'WhatsUpBiz',
};

export const siteContactTypes = {
  100: 'Контактная информация',
  200: 'Офис продаж',
  300: 'Контактный телефон',
};

export const feedLinks = {
  1: [`${feedsBaseUrl}avito/`],
  2: [`${feedsBaseUrl}incom-project/`, `${feedsBaseUrl}incom-image/`],
  3: [`${feedsBaseUrl}cian/`],
  4: [`${feedsBaseUrl}yandex/`],
  5: [`${feedsBaseUrl}luxury/`],
  6: [`${feedsBaseUrl}google/`],
  7: [`${feedsBaseUrl}facebook/`],
  8: [`${feedsBaseUrl}domclick/`],
  9: [`${feedsBaseUrl}yandex-direct/`],
  10: [`${feedsBaseUrl}vkontakte/`],
  11: [`${feedsBaseUrl}villagio-home/`],
  12: [`${feedsBaseUrl}tilda/`],
  13: [`${feedsBaseUrl}telegram-project/`, `${feedsBaseUrl}telegram-image/`],
  14: [`${feedsBaseUrl}avito-foreign/`],
};

export const liquidityTypes = {
  100: 'Максимально ликвидный - продастся быстро (3-6 мес.)',
  200: 'Ликвидный - продастся быстро (до 9 мес.)',
  300: 'В рынке - продастся (до 1 года)',
  400: 'Не ликвидный - будет продаваться долго  (дольше 1 / 1,5 года)',
  500: 'Максимально не ликвидный - (не продастся ближайшие 2 года)',
};

export const contractTypes = {
  100: 'Покупка',
  200: 'Продажа',
  300: 'Аренда',
  400: 'Сдача',
  500: 'Прогрев',
};

export const defaultContractStatuses = {
  100: 'Взят в работу экспертом',
  500: 'Проведён первый показ',
  600: 'Идут показы',
  800: 'Аванс',
  900: 'Ожидает подписания',
  1000: 'Ожидает оплаты',
  20050: 'Нецелевой',
  10000: 'Закрыт - Сделка состоялась',
  20000: 'Закрыт - Отказ',
};

export const contractStatusesForBuy = {
  ...defaultContractStatuses,
  400: 'Назначен первый показ',
  700: 'Выбран объект',
  20100: 'Закрыт - Отложенный интерес',
};

export const contractStatusesForSell = {
  ...defaultContractStatuses,
  200: 'Договор подписан',
  300: 'Объект размещен в рекламу',
  20200: 'Закрыт - Продан / Сдан конкурентами',
};

export const contractStatuses = {
  ...contractStatusesForSell,
  ...contractStatusesForBuy,
};

export const showStatuses = {
  // 100: 'Скоро',
  200: 'Назначен',
  300: 'Проведён',
  400: 'Отменён',
  500: 'Просрочен',
  // 600: 'Перенесён',
};

export const showCalcStatuses = {
  100: 'Назначен',
  200: 'Проведён',
  300: 'Просрочен',
};

export const showCancels = {
  100: 'Передумал смотреть',
  200: 'Уехал',
  300: 'Заболел',
  400: 'Не выходит на связь',
  500: 'Взял паузу',
  600: 'Невозможно показать',
  700: 'Уже купил',
  800: 'Передумал покупать',
};

export const showResults = {
  100: 'Ничего не понравилось',
  200: 'Понравилось, думает',
  300: 'Понравилось, переговоры',
  400: 'Дорого',
  500: 'Нецелевой турист',
  600: 'Понравилось, бронь',
};

export const showReschedulings = {
  100: 'Изменились планы',
  1000: 'Другое',
};

export const communicationTypes = {
  100: 'Водопровод',
  200: 'Газ',
  300: 'Интернет',
  400: 'Канализация',
  500: 'Теле',
  600: 'Телефон',
  700: 'Электро',
  10000: 'Другое',
};

export const clientProcessStatuses = {
  100: 'Не обработан',
  200: 'В процессе дозвона',
  300: 'Обработан',
};

export const textMLinkPositions = {
  100: 'Cлева',
  200: 'По центру',
  300: 'Справа',
};

export const villageSchemeLinks = {};

export const realtyGroupCategories = {
  100: 'Городская',
  200: 'Загородная',
  300: 'Международная',
};

export const metricPositions = {
  100: 'Head',
  200: 'Header',
  300: 'Footer',
};
