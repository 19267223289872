import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
// import { browserHistory } from '../../App';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
}));

export const LoadingPage = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" size={70} />
    </Backdrop>
  );
};
