import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react';

import { Admin, Resource } from 'react-admin';
import { createBrowserHistory } from 'history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import {
  Notifications as NotificationsIcon,
  Fastfood as FastfoodIcon,
  Wc as WcIcon,
  Person as PersonIcon,
  Receipt as ReceiptIcon,
  History as HistoryIcon,
  ViewList as ViewListIcon,
  LocalAtm as LocalAtmIcon,
  ArtTrack as ArtTrackIcon,
  Contacts as ContactsIcon,
  Web as WebIcon,
  EventNote as EventNoteIcon,
  MenuBook as MenuBookIcon,
  FiberNew as FiberNewIcon,
  Widgets as WidgetsIcon,
  Pages as PagesIcon,
  Repeat as RepeatIcon,
  AccountTree as AccountTreeIcon,
  Equalizer as EqualizerIcon,
  Room as RoomIcon,
} from '@material-ui/icons';
import { dataProvider } from './dataProvider';
import { authProvider, setOnGetToken, getOidc, setOnGetCurrentUser } from './authProvider';
import customRoutes from './customRoutes';
import { customTheme } from './theme';
import { SideMenu } from './components';

import ListSets from './pages/sets/List';
import { ListLogins, EditLogins } from './pages/logins';
import { ListEnum, CreateEnum, EditEnum } from './components/app';
import {
  CreateObjects,
  EditObjects,
  ListObjects,
  CreateVillages,
  EditVillages,
  ListVillages,
  CreateSets,
  EditSets,
  ListInfrastructures,
  CreateInfrastructures,
  EditInfrastructures,
  ListFeeds,
  CreateFeeds,
  EditFeeds,
  ListChangeHistory,
  CreateUsers,
  EditUsers,
  ListUsers,
  ListSectors,
  EditSectors,
  CreateSectors,
  ListTasks,
  EditTasks,
  CreateTasks,
  ListTaskHistory,
  LoginPage,
  ListArticles,
  EditArticles,
  CreateArticles,
  ListTouches,
  EditTouches,
  CreateTouches,
  EditDiscounts,
  CreateDiscounts,
  ListDiscounts,
  ListClients,
  EditClients,
  CreateClients,
  ListPromos,
  EditPromos,
  CreatePromos,
  ListCMSContacts,
  CreateCMSContacts,
  EditCMSContacts,
  ListFeatures,
  CreateFeatures,
  EditFeatures,
  ListContracts,
  CreateContracts,
  EditContracts,
  ListShows,
  CreateShows,
  EditShows,
  ListInstructions,
  CreateInstructions,
  EditInstructions,
  ListOurNews,
  CreateOurNews,
  EditOurNews,
  ListContractHistory,
  ListClientsHistory,
  Dashboard,
  ListCMSPages,
  CreateCMSPages,
  EditCMSPages,
  ListMagazineMainPages,
  EditMagazineMainPages,
  ListMagazineCategoryPages,
  CreateMagazineCategoryPages,
  EditMagazineCategoryPages,
  ListMagazineArticlePages,
  CreateMagazineArticlePages,
  EditMagazineArticlePages,
  ListGeoSeeds,
  CreateGeoSeeds,
  EditGeoSeeds,
  ListBaseObjects,
  EditBaseObjects,
  CreateBaseObjects,
  LoadingPage,
} from './pages';
import {
  ImageFullDialogProvider,
  TouchesWebsocketProvider,
  OidcProvider,
  ClientsWebsocketProvider,
  InstructionsProvider,
} from './components/providers';

import { initWebsockets } from './websockets';
import { checkRoleAdminOrSupervisor, checkRoleEditor, checkRoleOdp } from './utils';
import { CreateCMSWidgets, EditCMSWidgets, ListCMSWidgets } from './pages/CMS/Widgets';
import {
  CreateCMSWidgetTemplates,
  EditCMSWidgetTemplates,
  ListCMSWidgetTemplates,
} from './pages/CMS/WidgetTemplates';
import { CreateCMSProjects, EditCMSProjects, ListCMSProjects } from './pages/CMS/Projects';
import {
  ObjectsIcon,
  VillagesIcon,
  CalendarIcon,
  ClientsIcon,
  ContractsIcon,
  SectorsIcon,
  SetsIcon,
  TouchesIcon,
} from './components/Icons';
import { EditFakeContracts } from './pages/FakeContracts';

// fix empty string; it contains special Unicode em-space
russianMessages.ra.boolean.null = ' ';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

export const browserHistory = createBrowserHistory();

const enums = [
  // {
  //   name: 'MarketingTags',
  //   label: 'Маркетинговые фишки',
  // },
  {
    name: 'RealtyObjectSetTypes',
    label: 'Типы подборок',
  },
  {
    name: 'RealtyObjectFeatures',
    label: 'Преимущества объектов',
  },
  {
    name: 'RealtyObjectStates',
    label: 'Состояние объектов',
  },
  {
    name: 'TerrainTags',
    label: 'Особенности участков',
  },
  {
    name: 'RealtyObjectStyles',
    label: 'Архитектурные стили',
  },
  {
    name: 'RealtyObjectMaterials',
    label: 'Материалы',
  },
  {
    name: 'RealtyObjectRoomsTypes',
    label: 'Типы комнат',
  },
  {
    name: 'RealtyObjectCommunications',
    label: 'Коммуникации',
  },
  {
    name: 'GeoTags',
    label: 'Локации',
  },
  {
    name: 'RealtyGroupFeatures',
    label: 'Преимущества комплексов',
  },
  {
    name: 'Developers',
    label: 'Застройщики',
  },
  {
    name: 'RealtyObjectTypes',
    label: 'Типы объектов',
  },
  {
    name: 'RealtyObjectTags',
    label: 'Тэги объектов',
  },
  {
    name: 'RealtyGroupTypes',
    label: 'Типы комплексов',
  },
  {
    name: 'InfrastructureTypes',
    label: 'Типы инфраструктуры',
  },
  {
    name: 'FeedTargets',
    label: 'Площадки фидов',
  },
  {
    name: 'RealtyLiquidityReason',
    label: 'Причины ликвидности',
  },
  {
    name: 'ClientTags',
    label: 'Клиентские тэги',
  },
  {
    name: 'ContractTags',
    label: 'Теги лидов',
  },
  {
    name: 'TaaskTags',
    label: 'Тэги задач',
  },
  {
    name: 'TaaskTypes',
    label: 'Типы задач',
  },
  {
    name: 'TaaskResults',
    label: 'Результат выполнения задач',
  },
  {
    name: 'RealtyObjectPayments',
    label: 'Виды оплаты',
  },
  {
    name: 'UserActivityTypes',
    label: 'Род деятельности',
  },
  {
    name: 'InterestSources',
    label: 'Источники первичного интереса',
  },
  {
    name: 'GeoSeedTypes',
    label: 'Типы географии',
  },
  {
    name: 'ContractCancelReasons',
    label: 'Причины отказов лидов',
  },
  {
    name: 'RequestChannels',
    label: 'Каналы обращений',
  },
  {
    name: 'SalesDirections',
    label: 'Направления',
  },
  {
    name: 'cmsAdmin/pageTags',
    label: 'Тэги страниц',
  },
  {
    name: 'cmsAdmin/pageTypes',
    label: 'Типы страниц',
  },
  {
    name: 'cmsAdmin/widgetTags',
    label: 'Тэги виджетов',
  },
  {
    name: 'cmsAdmin/widgetTemplateTypes',
    label: 'Типы шаблонов виджетов',
  },
];

let isCallsWebsocketConnected = false;

const App = () => {
  const [oidc, setOidc] = useState();
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState();
  const onSetTouchRef = useRef();
  const onSetClientRef = useRef();

  const onGetToken = useCallback((currentOidc) => {
    setOidc(currentOidc);
    if (!isCallsWebsocketConnected) {
      const callbacks = {
        ClientTouches: onSetTouchRef,
        ClientProcessStatuses: onSetClientRef,
      };
      initWebsockets(currentOidc, callbacks);
      isCallsWebsocketConnected = true;
    }
  }, []);

  const onGetCurrentUser = useCallback((newCurrentUser) => {
    setCurrentUser(newCurrentUser);
  }, []);

  useEffect(() => {
    setOnGetToken(onGetToken);
    setOnGetCurrentUser(onGetCurrentUser);
    const currentOidc = getOidc();
    setOidc(currentOidc);

    if (!currentOidc) {
      return;
    }

    onGetToken(currentOidc);
  }, [onGetCurrentUser, onGetToken]);

  const permissions = useMemo(() => {
    if (!oidc) {
      return null;
    }

    return oidc.profile.role;
  }, [oidc]);

  const isAdmin = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const isOdp = useMemo(() => {
    return checkRoleOdp(permissions);
  }, [permissions]);

  const isEditor = useMemo(() => {
    return checkRoleEditor(permissions);
  }, [permissions]);

  const enumElements = useMemo(() => {
    return enums.map((enumItem) => {
      return (
        <Resource
          options={{ label: enumItem.label, subMenu: 'Справочники', subMenuIcon: ViewListIcon }}
          name={enumItem.name}
          list={ListEnum}
          create={CreateEnum}
          edit={EditEnum}
          key={enumItem.name}
          {...((enumItem.name === 'RealtyObjectFeatures' ||
            enumItem.name === 'RealtyGroupFeatures') && {
            list: ListFeatures,
            edit: EditFeatures,
            create: CreateFeatures,
          })}
        />
      );
    });
  }, []);

  const editorElements = useMemo(() => {
    const items = [
      <Resource
        options={{ label: 'Основные страницы', subMenu: 'Magazine', subMenuIcon: WebIcon }}
        name="cmsAdmin/magazineMainPages"
        key="cmsAdmin/magazineMainPages"
        list={ListMagazineMainPages}
        // create={CreateMagazineMainPages}
        edit={EditMagazineMainPages}
        icon={PagesIcon}
      />,
      <Resource
        options={{ label: 'Рубрики', subMenu: 'Magazine', subMenuIcon: WebIcon }}
        name="cmsAdmin/magazineCategoryPages"
        key="cmsAdmin/magazineCategoryPages"
        list={ListMagazineCategoryPages}
        create={CreateMagazineCategoryPages}
        edit={EditMagazineCategoryPages}
        icon={PagesIcon}
      />,
      <Resource
        options={{ label: 'Статьи', subMenu: 'Magazine', subMenuIcon: WebIcon }}
        name="cmsAdmin/magazineArticlePages"
        key="cmsAdmin/magazineArticlePages"
        list={ListMagazineArticlePages}
        create={CreateMagazineArticlePages}
        edit={EditMagazineArticlePages}
        icon={PagesIcon}
      />,
    ];

    if (isEditor) {
      items.push(
        <Resource
          options={{ label: 'Виджеты', subMenu: 'Magazine', subMenuIcon: WebIcon }}
          name="cmsAdmin/widgets"
          key="cmsAdmin/widgets"
          list={ListCMSWidgets}
          create={CreateCMSWidgets}
          edit={EditCMSWidgets}
          icon={WidgetsIcon}
        />,
      );
    }

    return items;
  }, [isEditor]);

  const mainElements = useMemo(() => {
    if (isEditor) {
      return editorElements;
    }

    const items = [
      <Resource
        options={{ label: 'Объекты' }}
        name="RealtyObjects"
        edit={EditObjects}
        create={CreateObjects}
        list={ListObjects}
        icon={ObjectsIcon}
        key="RealtyObjects"
      />,
      <Resource
        options={{ label: 'Базовые объекты' }}
        name="RealtyBaseObjects"
        edit={EditBaseObjects}
        create={CreateBaseObjects}
        list={ListBaseObjects}
        icon={ObjectsIcon}
        key="RealtyBaseObjects"
      />,
      <Resource
        options={{ label: 'Комплексы' }}
        name="RealtyGroups"
        key="RealtyGroups"
        edit={EditVillages}
        create={CreateVillages}
        list={ListVillages}
        icon={VillagesIcon}
      />,

      <Resource
        options={{ label: 'Клиенты' }}
        name="Clients"
        key="Clients"
        edit={EditClients}
        list={ListClients}
        create={CreateClients}
        icon={ClientsIcon}
      />,
      <Resource
        options={{ label: 'Лиды' }}
        name="Contracts"
        key="Contracts"
        edit={EditContracts}
        list={ListContracts}
        create={CreateContracts}
        icon={ContractsIcon}
      />,
      <Resource
        options={{ label: 'Календарь' }}
        name="calendar"
        key="calendar"
        icon={CalendarIcon}
      />,

      <Resource
        options={{ label: 'Подборки' }}
        name="RealtyObjectSets"
        key="RealtyObjectSets"
        edit={EditSets}
        create={CreateSets}
        list={ListSets}
        icon={SetsIcon}
      />,

      <Resource
        options={{ label: 'Кварталы' }}
        name="RealtySectors"
        key="RealtySectors"
        edit={EditSectors}
        create={CreateSectors}
        list={ListSectors}
        icon={SectorsIcon}
      />,
    ];

    if (isAdmin) {
      items.push(
        <Resource
          options={{ label: 'Фейковые Лиды' }}
          name="FakeContracts"
          key="FakeContracts"
          edit={EditFakeContracts}
        />,
      );
    }

    if (isAdmin) {
      items.push(
        <Resource
          options={{ label: 'Аналитика' }}
          name="analytics"
          key="analytics"
          icon={EqualizerIcon}
        />,
      );
    }

    if (isOdp || isAdmin) {
      items.push(
        <Resource
          options={{ label: 'Касания' }}
          name="ClientTouches"
          key="ClientTouches"
          list={ListTouches}
          edit={EditTouches}
          create={CreateTouches}
          icon={TouchesIcon}
        />,
      );
    }

    items.push(
      <Resource
        options={{ label: 'Показы' }}
        name="Demos"
        key="Demos"
        edit={EditShows}
        list={ListShows}
        create={CreateShows}
        icon={EventNoteIcon}
      />,
    );

    items.push(
      <Resource
        options={{ label: 'Люди' }}
        name="Peoples"
        key="Peoples"
        edit={EditUsers}
        create={CreateUsers}
        list={ListUsers}
        icon={WcIcon}
      />,
    );

    if (isAdmin) {
      items.push(
        <Resource
          options={{ label: 'Логины' }}
          name="users"
          key="users"
          edit={EditLogins}
          list={ListLogins}
          icon={PersonIcon}
        />,
        <Resource
          options={{ label: 'Инфраструктура' }}
          name="Infrastructures"
          key="Infrastructures"
          edit={EditInfrastructures}
          create={CreateInfrastructures}
          list={ListInfrastructures}
          icon={FastfoodIcon}
        />,
        <Resource
          options={{ label: 'География' }}
          name="GeoSeeds"
          key="GeoSeeds"
          edit={EditGeoSeeds}
          create={CreateGeoSeeds}
          list={ListGeoSeeds}
          icon={RoomIcon}
        />,
      );
    }

    items.push(
      <Resource
        options={{ label: 'Задачи' }}
        name="Taasks"
        key="Taasks"
        list={ListTasks}
        edit={EditTasks}
        create={CreateTasks}
        icon={NotificationsIcon}
      />,
    );

    if (isAdmin) {
      items.push(
        <Resource
          options={{ label: 'История изменений' }}
          name="ChangeHistory"
          key="ChangeHistory"
          list={ListChangeHistory}
          icon={HistoryIcon}
        />,
        <Resource
          options={{ label: 'История клиентов' }}
          name="ClientsHistory"
          key="ClientsHistory"
          list={ListClientsHistory}
          icon={HistoryIcon}
        />,
        <Resource
          options={{ label: 'История лидов' }}
          name="ContractHistory"
          key="ContractHistory"
          list={ListContractHistory}
          icon={HistoryIcon}
        />,
        <Resource
          options={{ label: 'История задач' }}
          name="TaaskHistory"
          key="TaaskHistory"
          list={ListTaskHistory}
          icon={HistoryIcon}
        />,
      );
    }

    if (isAdmin) {
      items.push(
        <Resource
          options={{ label: 'Фиды' }}
          name="RealtyFeeds"
          key="RealtyFeeds"
          edit={EditFeeds}
          create={CreateFeeds}
          list={ListFeeds}
          icon={ReceiptIcon}
        />,
        <Resource
          options={{ label: 'Скидки / Акции' }}
          name="RealtyObjectDiscounts"
          key="RealtyObjectDiscounts"
          list={ListDiscounts}
          edit={EditDiscounts}
          create={CreateDiscounts}
          icon={LocalAtmIcon}
        />,
        <Resource
          options={{ label: 'Спецпредложения' }}
          name="RealtyPromos"
          key="RealtyPromos"
          list={ListPromos}
          edit={EditPromos}
          create={CreatePromos}
          icon={LocalAtmIcon}
        />,
        <Resource
          options={{ label: 'Статьи' }}
          name="Articles"
          key="Articles"
          list={ListArticles}
          edit={EditArticles}
          create={CreateArticles}
          icon={ArtTrackIcon}
        />,
        <Resource
          options={{ label: 'Инструкции' }}
          name="Instructions"
          key="Instructions"
          list={ListInstructions}
          edit={EditInstructions}
          create={CreateInstructions}
          icon={MenuBookIcon}
        />,
        <Resource
          options={{ label: 'Пресса о нас' }}
          name="OurNews"
          key="OurNews"
          list={ListOurNews}
          edit={EditOurNews}
          create={CreateOurNews}
          icon={FiberNewIcon}
        />,
        ...enumElements,
        <Resource
          options={{ label: 'Контакты', subMenu: 'CMS', subMenuIcon: WebIcon }}
          name="SiteContacts"
          key="SiteContacts"
          list={ListCMSContacts}
          create={CreateCMSContacts}
          edit={EditCMSContacts}
          icon={ContactsIcon}
        />,
        <Resource
          options={{ label: 'Проекты', subMenu: 'CMS', subMenuIcon: WebIcon }}
          name="cmsAdmin/projects"
          key="cmsAdmin/projects"
          list={ListCMSProjects}
          create={CreateCMSProjects}
          edit={EditCMSProjects}
          icon={AccountTreeIcon}
        />,
        <Resource
          options={{ label: 'Страницы', subMenu: 'CMS', subMenuIcon: WebIcon }}
          name="cmsAdmin/pages"
          key="cmsAdmin/pages"
          list={ListCMSPages}
          create={CreateCMSPages}
          edit={EditCMSPages}
          icon={PagesIcon}
        />,
        <Resource
          options={{ label: 'Виджеты', subMenu: 'CMS', subMenuIcon: WebIcon }}
          name="cmsAdmin/widgets"
          key="cmsAdmin/widgets"
          list={ListCMSWidgets}
          create={CreateCMSWidgets}
          edit={EditCMSWidgets}
          icon={WidgetsIcon}
        />,
        <Resource
          options={{ label: 'Шаблоны виджетов', subMenu: 'CMS', subMenuIcon: WebIcon }}
          name="cmsAdmin/widgetTemplates"
          key="cmsAdmin/widgetTemplates"
          list={ListCMSWidgetTemplates}
          create={CreateCMSWidgetTemplates}
          edit={EditCMSWidgetTemplates}
          icon={RepeatIcon}
        />,
      );
    }

    if (isAdmin) {
      items.push(...editorElements);
    }

    return items;
  }, [editorElements, enumElements, isAdmin, isEditor, isOdp]);

  return (
    <OidcProvider oidc={oidc}>
      <TouchesWebsocketProvider onSetTouchRef={onSetTouchRef}>
        <ClientsWebsocketProvider onSetClientRef={onSetClientRef}>
          <ImageFullDialogProvider>
            <InstructionsProvider oidc={oidc}>
              <Admin
                i18nProvider={i18nProvider}
                history={browserHistory}
                authProvider={authProvider}
                dataProvider={dataProvider}
                customRoutes={customRoutes}
                dashboard={isEditor || !currentUser ? null : Dashboard}
                // layout={C.Layout}
                layout={SideMenu}
                loginPage={LoginPage}
                theme={customTheme}
                ready={LoadingPage}
              >
                {oidc && mainElements}
              </Admin>
            </InstructionsProvider>
          </ImageFullDialogProvider>
        </ClientsWebsocketProvider>
      </TouchesWebsocketProvider>
    </OidcProvider>
  );
};

export default App;
