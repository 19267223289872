import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { FormDataConsumer, FormTab } from 'react-admin';

import { NumericFormat } from 'react-number-format';
import { TextInput } from '../../TextInput/TextInput';
import { BooleanInput, FormTitle, NumberInput, TabbedForm } from '../../common';

import { getFullName, validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { FormCard } from '../../FormCard';
import {
  AutocompleteWithFormRow,
  CommentsTab,
  ConstantSelect,
  List,
  TabWithFooter,
  TasksTab,
} from '../../app';
import {
  contractStatusesForSell,
  contractStatusesForBuy,
  contractTypes,
  luxuryClasses,
  currencyTypes,
  initialSales,
  contractStatusFilledColors,
} from '../../../constants';
import { PeopleSelect } from '../../RASelects';
import { DynamicList } from '../../additionalLists';
import { currentUser } from '../../../authProvider';
import {
  AddCommentDialogButton,
  CreateShowDialogButton,
  CreateTaskDialogButton,
} from '../../buttons';
import { DateTimePickerInput } from '../../RAInputs';
import { ListItem } from '../../app/ListItems';
import { Toggler } from '../../Toggler';

export const СustomNumericInput = (props) => {
  const { value, onChange, name, inputRef, type, ...other } = props;

  return (
    <NumericFormat
      {...other}
      value={value}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandsGroupStyle="thousand"
      thousandSeparator=" "
    />
  );
};

export const movedToSPItems = [
  {
    text: 'Передан в сервиспоинт',
    value: true,
  },
  {
    text: 'Передача не требуется',
    value: false,
  },
];

export const movedToSupportItems = [
  {
    text: 'Передан в ССПС',
    value: true,
  },
  {
    text: 'Передача не требуется',
    value: false,
  },
];

const mapTechSourceToTequestChannel = {
  100: 1,
  200: 3,
  300: 1,
  400: 2,
  500: 1,
  600: 2,
  700: 2,
};

export const TogglerWithFormRow = ({ formRowProps, ...props }) => {
  return (
    <FormRow {...formRowProps}>
      <Toggler {...props} />
    </FormRow>
  );
};

export const FormContracts = (props) => {
  const { id, record, location } = props;

  const [status, setStatus] = useState(parseInt(record.status, 10));
  const [mainClient, setMainClient] = useState(record.mainClient);

  useEffect(() => {
    setStatus(parseInt(record.status, 10));
    setMainClient(record.mainClient);
  }, [record]);

  const defaultMainClient = useMemo(() => {
    let currentMainClient = location.state?.item;
    if (location.state?.type === 'touch') {
      currentMainClient = location.state?.item?.client;
    }

    return currentMainClient;
  }, [location.state]);

  const defaultTouch = useMemo(() => {
    let touch;
    if (location.state?.type === 'touch') {
      touch = location.state?.item;
    }

    return touch;
  }, [location.state]);

  const defaultRequestChannel = useMemo(() => {
    if (
      location.state?.type === 'touch' &&
      location.state?.item.techSource in mapTechSourceToTequestChannel
    ) {
      return mapTechSourceToTequestChannel[location.state.item.techSource];
    }
  }, [location.state]);

  const showsTabFooter = useMemo(() => {
    return (
      <>
        <CreateShowDialogButton defaultContract={record} />
      </>
    );
  }, [record]);

  const logTabFooter = useMemo(() => {
    return (
      <>
        <CreateShowDialogButton
          style={{ marginRight: '12px', marginBottom: '8px' }}
          defaultContract={record}
        />
        <CreateTaskDialogButton
          style={{ marginRight: '12px', marginBottom: '8px' }}
          type={700}
          defaultItem={record}
        />
        <AddCommentDialogButton style={{ marginBottom: '8px' }} type={500} id={id} />
      </>
    );
  }, [id, record]);

  const handleChangeStatus = useCallback((event) => {
    setStatus(parseInt(event.target.value, 10));
  }, []);

  const handleChangeMainClient = useCallback((newValue) => {
    setMainClient(newValue);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [externalExpertDirectionId, setExternalExpertDirectionId] = useState();
  const [expertDirection, setExpertDirection] = useState(record.expertSalesDirectionId);

  const handleChangeExpert = useCallback(
    (newValue) => {
      let resultValue;
      if (newValue?.salesDirectionId && !expertDirection) {
        resultValue = newValue.salesDirectionId;
      }
      setExternalExpertDirectionId(resultValue);
    },
    [expertDirection],
  );

  const handleChangeExpertDirectionId = useCallback((newValue) => {
    setExpertDirection(newValue);
  }, []);

  const [externalDirectionId, setExternalDirectionId] = useState();

  const handleChangeInitialRealtyObject = useCallback((newValue) => {
    let resultValue;
    if (newValue?.ownSalesDirectionId) {
      resultValue = newValue.ownSalesDirectionId;
    }
    setExternalDirectionId(resultValue);
  }, []);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <>
              <FormRow>
                <TextInput source="id" label="Id" disabled />
              </FormRow>
              <FormRow>
                <DateTimePickerInput source="createdDate" label="Дата создания" disabled />
              </FormRow>
            </>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="clientTouch"
            resourceName="clientTouches"
            resourceId="clientTouchId"
            // validate={validates.touchContract}
            componentProps={{
              label: 'Целевое касание',
              getOptionLabel: (option) => {
                let label = `${option.id}`;
                if (option.client) {
                  label += ` | ${getFullName(option.client)}`;
                }

                if (option.externalPhoneNumber) {
                  label += ` | ${option.externalPhoneNumber}`;
                }

                return label;
              },
              defaultValue: defaultTouch,
              fullDynamic: 'IdPhoneContains',
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="interestSources"
            resourceName="interestSources"
            resourceId="interestSourceId"
            additionalSource="interestSource"
            componentProps={{
              label: 'Источники первичного интереса',
              filterSelectedOptions: true,
              multiple: true,
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="requestChannel"
            resourceName="requestChannels"
            resourceId="requestChannelId"
            componentProps={{
              label: 'Канал обращения',
              filterSelectedOptions: true,
              defaultValue: defaultRequestChannel,
              needLoadDefaultValue: true,
            }}
          />
          <FormRow>
            <ConstantSelect
              source="type"
              objectChoices={contractTypes}
              label="Сценарий лида"
              defaultValue={100}
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              const type = Number(formData.type);
              const isBuyAndRent = type === 100 || type === 300;

              if (status !== 10000) {
                formData.isMovedToSP = null;
                formData.isMovedToSupport = null;
              }

              return (
                <>
                  <FormRow>
                    <ConstantSelect
                      source="status"
                      objectChoices={
                        isBuyAndRent ? contractStatusesForBuy : contractStatusesForSell
                      }
                      label="Этап воронки"
                      onChange={handleChangeStatus}
                      allowEmpty
                      filledColors={contractStatusFilledColors}
                      validate={validates.required}
                    />
                  </FormRow>
                  {(status === 20000 || status === 20050) && (
                    <AutocompleteWithFormRow
                      {...props}
                      source="contractCancelReason"
                      resourceName="ContractCancelReasons"
                      resourceId="contractCancelReasonId"
                      componentProps={{
                        label: 'Причина отказа/нецелевого',
                        filterSelectedOptions: true,
                      }}
                      validate={validates.required}
                    />
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <PeopleSelect
            source="createdUser"
            resourceId="createdUserId"
            {...props}
            componentProps={{
              label: 'Создатель лида',
              disabled: true,
              defaultValue: currentUser,
            }}
          />
          <PeopleSelect
            source="firstExpert"
            resourceId="firstExpertId"
            {...props}
            componentProps={{
              label: 'Эксперт принял лид',
              providerParams: { filter: { isExpert: true } },
            }}
          />
          <PeopleSelect
            source="currentExpert"
            resourceId="currentExpertId"
            validate={validates.currentExpertContract}
            onChange={handleChangeExpert}
            {...props}
            componentProps={{
              label: 'Эксперт текущий',
              providerParams: { filter: { isExpert: true, FlatDetails: 300 } },
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="expertSalesDirection"
            resourceName="SalesDirections"
            resourceId="expertSalesDirectionId"
            onChange={handleChangeExpertDirectionId}
            // validate={validates.required}
            componentProps={{
              label: 'Направление эксперта',
              externalValue: externalExpertDirectionId,
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="contractTags"
            resourceName="contractTags"
            resourceId="contractTagId"
            additionalSource="contractTag"
            componentProps={{
              label: 'Теги лидов',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="initialRealtyObject"
            resourceName="RealtyObjects"
            resourceId="initialRealtyObjectId"
            componentProps={{
              label: 'Объект обращения',
              fullDynamic: 'QueryWithFid',
              providerParams: { filter: { FlatDetails: 300 } },

              getOptionLabel: (option) => {
                if (!option) {
                  return '';
                }

                return `${option.id}: ${option.name || ''}`;
              },
            }}
            onChange={handleChangeInitialRealtyObject}
          />
          <AutocompleteWithFormRow
            {...props}
            source="clientSalesDirection"
            resourceName="SalesDirections"
            resourceId="clientSalesDirectionId"
            // validate={validates.required}
            componentProps={{
              label: 'Направление',
              externalValue: externalDirectionId,
            }}
          />
          <FormRow maxWidth={800} isLast>
            <TextInput
              source="comment"
              label="Комментарий"
              multiline
              rows={6}
              validate={validates.contarctComment}
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="isInitialSale"
              objectChoices={initialSales}
              label="Первичка или вторичка?"
              allowEmpty
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              const type = Number(formData.type);
              const isBuyAndRent = type === 100 || type === 300;

              return (
                <>
                  <AutocompleteWithFormRow
                    {...props}
                    source="realtyObjectType"
                    resourceName="RealtyObjectTypes"
                    resourceId="realtyObjectTypeId"
                    componentProps={{
                      label: isBuyAndRent ? 'Что ищет' : 'Что продает/сдает',
                      filterSelectedOptions: true,
                    }}
                  />
                  <FormRow>
                    <NumberInput
                      source="budget"
                      label={isBuyAndRent ? 'Бюджет' : 'Цена'}
                      min={0}
                      InputProps={{
                        startAdornment: (
                          <span style={{ marginRight: '6px', paddingTop: '15px' }}>
                            {isBuyAndRent ? 'до' : 'от'}
                          </span>
                        ),
                        inputComponent: СustomNumericInput,
                      }}
                    />
                  </FormRow>
                  <FormRow>
                    <ConstantSelect
                      source="luxuryClass"
                      objectChoices={luxuryClasses}
                      label="Сегмент при обращении"
                      allowEmpty
                    />
                  </FormRow>
                  <FormRow>
                    <NumberInput source="floorArea" label="Площадь" />
                  </FormRow>
                  <FormRow>
                    <NumberInput source="plottage" label="Площадь участка" />
                  </FormRow>
                  {/* <FormRow>
                    <BooleanInput label="Комплексы Villagio Estate" source="isVillagio" />
                  </FormRow> */}
                  <FormRow>
                    <BooleanInput
                      label={isBuyAndRent ? 'Пригласил на показ' : 'Предложил посмотреть дом'}
                      source="isInvited"
                    />
                  </FormRow>
                  <AutocompleteWithFormRow
                    {...props}
                    source="realtyGroups"
                    resourceName="RealtyGroups"
                    resourceId="realtyGroupId"
                    additionalSource="realtyGroup"
                    componentProps={{
                      label: 'Комплексы обращения',
                      fullDynamic: 'NameContains',
                      multiple: true,
                      filterSelectedOptions: true,
                    }}
                  />
                  {!isBuyAndRent && (
                    <>
                      <AutocompleteWithFormRow
                        {...props}
                        source="realtyObject"
                        resourceName="RealtyObjects"
                        resourceId="realtyObjectId"
                        componentProps={{
                          label: 'Объект продажи/сдачи',
                          fullDynamic: 'QueryWithFid',
                          getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
                        }}
                      />
                    </>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
        </FormCard>
      </FormTab>
      <FormTab label="Клиенты">
        <FormCard>
          <PeopleSelect
            source="mainClient"
            resourceId="mainClientId"
            validate={validates.mainClient}
            {...props}
            componentProps={{
              label: 'Основной клиент',
              providerParams: { filter: { isClient: true } },
              defaultValue: defaultMainClient,
            }}
            onChange={handleChangeMainClient}
          />
          {mainClient && (
            <>
              <FormRow>
                <ListItem type="client" record={mainClient} hasButtons={false} isNewItem />
              </FormRow>
            </>
          )}
          <PeopleSelect
            source="otherPeoples"
            resourceId="userId"
            additionalSource="user"
            {...props}
            componentProps={{
              label: 'Доп. участники',
              providerParams: { filter: { isClient: true } },
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
        </FormCard>
      </FormTab>
      {status === 10000 && (
        <FormTab label="Данные по сделке">
          <FormCard>
            <FormDataConsumer>
              {({ formData }) => {
                const type = Number(formData.type);
                const isBuyAndRent = type === 100 || type === 300;

                return (
                  <>
                    {isBuyAndRent && (
                      <>
                        <AutocompleteWithFormRow
                          {...props}
                          source="realtyObject"
                          resourceName="RealtyObjects"
                          resourceId="realtyObjectId"
                          validate={validates.required}
                          componentProps={{
                            label: 'Объект в сделке',
                            fullDynamic: 'QueryWithFid',
                            getOptionLabel: (option) =>
                              `${option.id}: ${option.name} | ${option.friendlyId}`,
                          }}
                        />
                      </>
                    )}
                  </>
                );
              }}
            </FormDataConsumer>
            <AutocompleteWithFormRow
              {...props}
              source="realtyObjectPayment"
              resourceName="RealtyObjectPayments"
              resourceId="realtyObjectPaymentId"
              componentProps={{
                label: 'Тип оплаты',
                filterSelectedOptions: true,
              }}
            />
            <PeopleSelect
              source="oppositeExpert"
              resourceId="oppositeExpertId"
              {...props}
              componentProps={{
                label: 'Эксперт продавца',
                providerParams: { filter: { isExpert: true } },
              }}
            />
            <FormRow>
              <DateTimePickerInput
                pickerType="date"
                source="closedDate"
                label="Дата закрытия"
                disabled
              />
            </FormRow>
            <FormTitle>Сроки сделки</FormTitle>
            <FormRow>
              <DateTimePickerInput
                pickerType="date"
                source="prepayDate"
                label="Дата аванса/задатка"
              />
            </FormRow>
            <FormRow>
              <DateTimePickerInput
                pickerType="date"
                source="prepayTillDate"
                label="Срок действия аванса/задатка до"
              />
            </FormRow>
            <FormRow>
              <DateTimePickerInput
                pickerType="date"
                source="dkpDate"
                label="Дата подписания ДКП"
                validate={validates.required}
              />
            </FormRow>
            <FormRow>
              <DateTimePickerInput
                pickerType="date"
                source="dvrDate"
                label="Дата поступления ДВР"
              />
            </FormRow>
            <FormTitle>Сумма сделки</FormTitle>
            <FormRow>
              <ConstantSelect
                source="dealCurrencyType"
                objectChoices={currencyTypes}
                label="Валюта стоимости продажи"
              />
            </FormRow>
            <FormRow>
              <NumberInput source="dealPrice" label="Стоимость продажи" />
            </FormRow>
            <FormRow>
              <NumberInput source="dealPriceInRub" label="Стоимость продажи в рублях" disabled />
            </FormRow>
            <FormTitle>Комиссия сделки</FormTitle>
            <FormRow>
              <ConstantSelect
                source="comissionCurrencyType"
                objectChoices={currencyTypes}
                label="Валюта комиссии"
              />
            </FormRow>
            <FormRow>
              <NumberInput source="comission" label="Комиссия" />
            </FormRow>
            <FormRow>
              <NumberInput source="comissionInRub" label="Комиссия в рублях" disabled />
            </FormRow>
            <FormRow>
              <NumberInput
                source="comissionPercents"
                label="Комиссия в процентах"
                InputProps={{
                  endAdornment: '%',
                }}
                disabled
              />
            </FormRow>
            <FormRow>
              <BooleanInput label="Сделка с участием агентства?" source="isWithAgency" />
            </FormRow>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  formData.isWithAgency && (
                    <>
                      <FormRow>
                        <NumberInput
                          source="agencyFeeInRub"
                          label="Сумма комиссии агентства в рублях"
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput
                          source="agencyFeePercents"
                          label="Сумма комиссии агентства в процентах"
                          InputProps={{
                            endAdornment: '%',
                          }}
                          disabled
                        />
                      </FormRow>
                    </>
                  )
                );
              }}
            </FormDataConsumer>
            <FormTitle>Передача клиента для сопровождения</FormTitle>
            <TogglerWithFormRow
              items={movedToSPItems}
              source="isMovedToSP"
              validate={validates.required}
            />
            <TogglerWithFormRow
              items={movedToSupportItems}
              source="isMovedToSupport"
              validate={validates.required}
            />
          </FormCard>
        </FormTab>
      )}
      {id && (
        <FormTab label="Показы">
          <TabWithFooter footerContent={showsTabFooter}>
            <DynamicList
              fetchOptions={{
                resource: 'Demos',
                filter: { ContractId: record.id },
              }}
              contentElement={List}
              contentOptions={{
                type: 'show',
                hasButtons: false,
                isNewItem: true,
              }}
            />
          </TabWithFooter>
        </FormTab>
      )}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={700} location={location} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Комментарии">
          <CommentsTab id={id} type={500} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Лог">
          <TabWithFooter footerContent={logTabFooter}>
            <DynamicList
              fetchOptions={{
                resource: `Contracts/${id}/mergedLog`,
              }}
              contentElement={List}
              contentOptions={{
                hasButtons: false,
                dynamicType: true,
              }}
              perPage={25}
            />
          </TabWithFooter>
        </FormTab>
      )}
    </TabbedForm>
  );
};
