// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState, useRef } from 'react';
import { useInput } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import { AddPhotoIcon, DeleteIcon } from '../Icons';
import { Preloader } from '../Preloader';
import { HelperText } from '../common';

import { getImageOrVideoPath, staticProvider } from '../../staticApi';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  wrapperFile: {
    position: 'relative',
    paddingBottom: 62,
  },
  wrapperImage: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    position: 'relative',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '4px',
    lineHeight: 0,
    fontSize: 0,
    width: '100%',
    height: (props) => (props.isVideo ? 318 : 262),
    paddingBottom: (props) => (props.isVideo ? 56 : 8),

    '& img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },

    '& video': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  buttonChange: {
    position: 'absolute',
    right: 56,
    bottom: 8,
    color: '#fff',
    background: 'rgba(30, 30, 30, 0.6)',
    borderRadius: '4px',
    fontSize: '13px',
    lineHeight: '1',
    height: 40,
    width: 113,
    boxSizing: 'border-box',
    minWidth: 0,
    border: 0,
    cursor: 'pointer',
    textTransform: 'uppercase',
    outline: 'none',
    fontFamily: 'Roboto',
  },
  buttonClear: {
    right: 8,
    bottom: 8,
    width: 40,
  },
  buttonAdd: {
    border: '1px solid #BDBDBD',
    color: '#1E1E1E',
    borderRadius: '4px',
    lineHeight: '1',
    padding: '13px 20px',
    fontSize: '14px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    textTransform: 'uppercase',
    outline: 'none',
    fontFamily: 'Roboto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      marginRight: '8px',
    },
  },
  helperText: {
    marginLeft: '14px',
    marginRight: '14px',
  },
});

const videoTypes = {
  ogv: 'ogg',
  mp4: 'mp4',
  webm: 'webm',
};

const FileAccept = 'application/pdf';

const getFormat = (path) => {
  return path.match(/[.]\w+$/)[0];
};

export const ImageInput = (props) => {
  const {
    source,
    multiple = false,
    record,
    helperText = '',
    isVideo,
    isFile,
    // eslint-disable-next-line no-nested-ternary
    buttonText = isVideo ? 'Добавить видео' : isFile ? 'Добавить pdf' : 'Добавить обложку',
  } = props;
  const classes = useStyles({ isVideo });
  const inputRef = useRef(null);
  const [format, setForamt] = useState();

  const { input, meta } = useInput({ ...props, defaultValue: '' });

  const initValue = record?.[source] || input.value;

  const [value, setValue] = useState(() => {
    if (initValue) {
      const path = getImageOrVideoPath({
        width: 384,
        height: 286,
        path: initValue,
        isVideo,
        isFile,
      });
      setForamt(getFormat(path));
      return path;
    }

    return '';
  });

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const handleChangeFile = useCallback(
    async (event) => {
      if (event.target.files) {
        setLoading(true);
        const formData = new FormData();
        Array.from(event.target.files).forEach((file) => {
          // eslint-disable-next-line no-nested-ternary
          formData.append(isVideo ? 'video' : isFile ? 'file' : 'image', file);
        });
        try {
          let result;
          if (isVideo) {
            result = await staticProvider.uploadVideo(formData);
          } else if (isFile) {
            result = await staticProvider.uploadFile(formData);
          } else {
            result = await staticProvider.uploadResizable(formData);
          }
          const url = getImageOrVideoPath({
            width: 384,
            height: 286,
            path: result.data.data[0].destination,
            isVideo,
            isFile,
          });
          setForamt(getFormat(url));
          setValue(url);
          input.onChange(result.data.data[0].destination);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [input, isFile, isVideo],
  );

  const handleClearFile = useCallback(() => {
    setValue(undefined);
    input.onChange('');
    inputRef.current.value = '';
  }, [input]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Preloader loading={loading} size={25} />
        <input
          className={classes.input}
          multiple={multiple}
          accept={
            // eslint-disable-next-line no-nested-ternary
            isVideo ? 'video/mp4, video/ogg, video/webm' : isFile ? FileAccept : 'image/*'
          }
          ref={inputRef}
          type="file"
          onChange={handleChangeFile}
        />
        {value ? (
          <div className={isFile ? classes.wrapperFile : classes.wrapperImage}>
            {
              // eslint-disable-next-line no-nested-ternary
              isVideo ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  controls
                  poster={
                    record?.videoPoster
                      ? getImageOrVideoPath({
                          width: 384,
                          height: 286,
                          path: record.videoPoster,
                        })
                      : false
                  }
                >
                  <source src={value} type={videoTypes[format]} />
                  Your browser doesn&apos;t support HTML5 video tag.
                </video>
              ) : isFile ? (
                <a href={value} target="_blank" download rel="noreferrer">
                  {value}
                </a>
              ) : (
                <img src={value} />
              )
            }
            <button type="button" className={classes.buttonChange} onClick={handleClick}>
              Изменить
            </button>
            <button
              type="button"
              className={`${classes.buttonClear} ${classes.buttonChange}`}
              onClick={handleClearFile}
            >
              <DeleteIcon width={22} height={24} />
            </button>
          </div>
        ) : (
          <button className={classes.buttonAdd} type="button" onClick={handleClick}>
            <AddPhotoIcon width={24} height={24} /> {buttonText}
          </button>
        )}
      </div>
      <HelperText {...meta} helperText={helperText} />
    </div>
  );
};
