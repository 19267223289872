import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { TextField, CircularProgress, Chip, IconButton, makeStyles } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { capitalizeFirstLetter } from '../../../utils';
import { EditIcon } from '../../Icons';

const defaultProviderParams = {
  filter: {},
};

const filter = createFilterOptions();

const DefaultLabel = ({ record, resource }) => {
  const redirect = useRedirect();

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.ctrlKey || event.metaKey) {
        window.open(`/${capitalizeFirstLetter(resource)}/${record.id}`, '_blank');
      } else {
        redirect('edit', `/${capitalizeFirstLetter(resource)}`, record.id);
      }
    },
    [record.id, redirect, resource],
  );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={handleClick} style={{ color: 'inherit' }}>
      {record.name}
    </a>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    '&:hover $editButton, & .Mui-focused $editButton': {
      display: 'block',
    },
  },

  editButton: {
    display: 'none',
  },
}));

export const AutocompleteDynamic = (props) => {
  const {
    resource,
    multiple,
    onChange,
    label,
    requestAdd,
    startAdornment,
    defaultValue,
    valueProp,
    setValueProp,
    providerParams = defaultProviderParams,
    fullDynamic,
    needLoadDefaultValue,
    onLoadDefaultValue,
    isRequired = false,
    hasError = false,
    minSearchLenght,
    name,
    externalValue,
  } = props;

  const redirect = useRedirect();

  const classes = useStyles();

  const dataProvider = useDataProvider();

  const [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    }

    if (multiple) {
      return [];
    }

    return null;
  });

  const [inputValue, setInputValue] = useState('');
  const oldInputValueRef = useRef();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const firstLoadingRef = useRef(false);
  const timerIdRef = useRef();

  useEffect(() => {
    if (externalValue) {
      setLoading(true);
      const fetch = async (currentResource, params) => {
        try {
          const result = await dataProvider.search(currentResource, params);
          setValue(multiple ? result.data : result.data[0]);
          onLoadDefaultValue(multiple ? result.data : result.data[0]);
          // eslint-disable-next-line no-empty
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      fetch(resource, {
        filter: {
          ids: multiple ? externalValue.join(',') : externalValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalValue]);

  useEffect(() => {
    if (needLoadDefaultValue && defaultValue) {
      setLoading(true);
      const fetch = async (currentResource, params) => {
        try {
          const result = await dataProvider.search(currentResource, params);
          setValue(multiple ? result.data : result.data[0]);
          onLoadDefaultValue(multiple ? result.data : result.data[0]);
          // eslint-disable-next-line no-empty
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      fetch(resource, {
        filter: {
          ids: multiple ? defaultValue.join(',') : defaultValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!open) {
      return;
    }

    const fetch = async (currentResource, params) => {
      // setLoading(true);
      try {
        const result = await dataProvider.search(currentResource, params);
        if (fullDynamic && inputValue !== oldInputValueRef.current) {
          return;
        }
        setOptions(result.data);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        if (fullDynamic && inputValue !== oldInputValueRef.current) {
          // eslint-disable-next-line no-unsafe-finally
          return;
        }
        setLoading(false);
      }
    };

    if (!firstLoadingRef.current && !fullDynamic) {
      setLoading(true);
      fetch(resource, providerParams);
    }

    if (!fullDynamic) {
      firstLoadingRef.current = true;
    }

    if (fullDynamic && inputValue !== oldInputValueRef.current) {
      oldInputValueRef.current = inputValue;
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
      setLoading(true);
      timerIdRef.current = setTimeout(() => {
        const newProviderParams = { ...providerParams, filter: { ...providerParams.filter } };
        newProviderParams.filter[fullDynamic] = inputValue;
        fetch(resource, newProviderParams);
      }, 500);
    }
  }, [dataProvider, resource, providerParams, open, inputValue, fullDynamic]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
      firstLoadingRef.current = false;
      oldInputValueRef.current = undefined;
    }
  }, [open]);

  const startAdornmentElement = useMemo(() => {
    if (!startAdornment) {
      return null;
    }

    return startAdornment(valueProp !== undefined ? valueProp : value);
  }, [startAdornment, value, valueProp]);

  const handleEdit = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.ctrlKey || event.metaKey) {
        window.open(
          `/${capitalizeFirstLetter(resource)}/${(valueProp !== undefined ? valueProp : value).id}`,
          '_blank',
        );
      } else {
        redirect(
          'edit',
          `/${capitalizeFirstLetter(resource)}`,
          (valueProp !== undefined ? valueProp : value).id,
        );
      }
    },
    [redirect, resource, valueProp, value],
  );

  return (
    <Autocomplete
      classes={{ root: classes.root }}
      noOptionsText={
        minSearchLenght && inputValue.length < minSearchLenght
          ? `Введите не менее ${minSearchLenght} символа(ов)`
          : 'No options'
      }
      getOptionSelected={(option, selectedValue) => {
        return option.id === selectedValue.id;
      }}
      getOptionLabel={(option) => {
        // временный костыль
        if (!option) {
          return '';
        }

        return `${option.name || ''}`;
      }}
      openOnFocus
      disableCloseOnSelect={multiple}
      ChipProps={{ color: 'primary' }}
      renderTags={(renderValue, getChipProps) => {
        return renderValue.map((option, index) => (
          <Chip
            label={<DefaultLabel record={option} resource={resource} />}
            {...getChipProps({ index })}
            color="primary"
          />
        ));
      }}
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      loading={loading}
      value={valueProp !== undefined ? valueProp : value}
      onChange={(event, newValue) => {
        const setValueFunc = (finalValue) => {
          if (setValueProp) {
            setValueProp(finalValue);
          } else {
            setValue(finalValue);
          }

          if (onChange) {
            onChange(finalValue, name);
          }
        };

        if (requestAdd && newValue && newValue.id === 'Создать') {
          setTimeout(() => {
            setLoading(true);
          }, 1);
          const fetch = async () => {
            try {
              const { data } = await requestAdd(newValue.name);
              setValueFunc(data);
            } catch (error) {
              console.log(error);
            } finally {
              setLoading(false);
            }
          };
          fetch();

          return;
        }

        setValueFunc(newValue);
      }}
      filterOptions={(filterOptions, params) => {
        const filtered = filter(filterOptions, params);
        if (minSearchLenght && params.inputValue.length < minSearchLenght) {
          return [];
        }

        if (requestAdd) {
          const foundItem = filtered.find((item) => {
            return item.name === params.inputValue;
          });

          if (params.inputValue !== '' && !foundItem) {
            filtered.push({
              id: 'Создать',
              name: params.inputValue,
            });
          }

          return filtered;
        }

        if (!fullDynamic) {
          return filtered;
        }

        return filterOptions;
      }}
      inputValue={inputValue}
      onInputChange={(event, text) => {
        setInputValue(text);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={hasError}
            required={isRequired}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  {!loading && !multiple && inputValue && (
                    <IconButton
                      size="small"
                      onClick={handleEdit}
                      style={{ padding: '6px' }}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                      }}
                      classes={{ root: classes.editButton }}
                    >
                      <EditIcon width={18} height={18} />
                    </IconButton>
                  )}
                </>
              ),
              startAdornment: (
                <div style={{ maxHeight: '115px', overflowY: 'auto' }}>
                  {startAdornmentElement || params.InputProps.startAdornment}
                </div>
              ),
            }}
          />
        );
      }}
    />
  );
};
