import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Tabs, Tab } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { StaticList } from '..';

import { AddButton, DownloadImages } from '../../buttons';

import { ImageOrVideo as ImageOrVideoDialog } from '../../dialogs';

import { useAdditionalTableValue } from '../../../hooks';
import { MultipleAddImages, DnDList } from '../../app';
import { MenuCell } from '../../tables';
import { ImagesSelectedMenu } from '../../app/SelectedMenus';
import { imageTypes } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '16px 16px 0 16px',
    boxSizing: 'border-box',

    [theme.breakpoints.down('600')]: {
      padding: '16px 12px',
    },
  },
  content: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '24px',
    flex: '0 0 auto',
  },
  title: {
    fontSize: '18px',
    lineHeight: '1.4',
    fontWeight: '500',
  },
  header_left: {
    flex: '1 1 auto',
    boxSizing: 'border-box',
    paddingRight: '20px',
  },
  header_right: {
    flex: '0 1 auto',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-5px 0',
  },
  header_right_row: {
    flex: '0 1 auto',
    alignItems: 'center',
    margin: '6px 12px 6px 0',
  },
  tab: {
    '& > span:first-child': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
}));

const ExtendedImageTypes = {
  0: 'Без типа',
  ...imageTypes,
};

const CardsImageOrVideos = ({
  menuItem,
  items,
  isVideo,
  onChangeItems,
  record,
  onDeleteItems,
  hasButtons,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const selectedIdsRef = useRef([]);

  const handleChangeSelectedIds = useCallback(
    (id) => {
      let newIds = [...selectedIds];
      if (newIds.includes(id)) {
        newIds = newIds.filter((selectedId) => {
          return selectedId !== id;
        });
      } else {
        newIds.push(id);
      }
      setSelectedIds(newIds);
      selectedIdsRef.current = newIds;
    },
    [selectedIds],
  );

  useEffect(() => {
    const newIds = selectedIdsRef.current.filter((selectedId) => {
      return items.find((item) => {
        return item.id === selectedId;
      });
    });

    setSelectedIds(newIds);
  }, [items]);

  const handleReset = useCallback(() => {
    setSelectedIds([]);
    selectedIdsRef.current = [];
  }, []);

  useEffect(() => {
    handleReset();
  }, [handleReset, record]);

  const onReset = useCallback(() => {
    handleReset();
  }, [handleReset]);

  return (
    <StaticList
      contentOptions={{
        type: isVideo ? 'video' : 'image',
        menuItem,
        onToggleItem: handleChangeSelectedIds,
        selectedIds,
        hasButtons,
      }}
      selectedOptions={{
        selectedMenuOptions: ImagesSelectedMenu,
        isVideo,
        onChangeItems,
        onReset,
        onDeleteItems,
      }}
      items={items}
      contentElement={DnDList}
      hasPagination={false}
    />
  );
};

const filterImagesByType = (items, imageType) => {
  return items.filter((item) => {
    let filteredType = Number(imageType);
    let newImageType = Number(item.imageType);
    if (!item.imageType) {
      newImageType = undefined;
    }
    if (filteredType === 0) {
      filteredType = undefined;
    }

    return newImageType === filteredType;
  });
};

const TableImagesOrVideo = ({
  items,
  onChange,
  onDelete,
  isVideo,
  record: propRecord,
  imageType,
  setImageType,
  parentType,
  hasTemplateName,
  disabled,
}) => {
  const classes = useStyles();
  const changeOpenRef = useRef();
  const [record, setRecord] = useState();

  const handleOpenDialog = useCallback((currentRecord) => {
    return () => {
      setRecord(currentRecord);
      if (changeOpenRef.current) {
        changeOpenRef.current(true);
      }
    };
  }, []);

  const handleDelete = useCallback(
    (currentRecord) => {
      return () => {
        if (onDelete) {
          onDelete(currentRecord.id);
        }
      };
    },
    [onDelete],
  );

  const menuItem = useCallback(
    (item) => {
      return (
        <MenuCell icon={<MoreVertIcon />}>
          <MenuItem onClick={handleOpenDialog(item)}>Редактировать</MenuItem>
          <MenuItem onClick={handleDelete(item)}>Удалить</MenuItem>
        </MenuCell>
      );
    },
    [handleDelete, handleOpenDialog],
  );

  const handleChangeType = useCallback(
    (event, newValue) => {
      setImageType(newValue);
    },
    [setImageType],
  );

  const filteredItems = useMemo(() => {
    return filterImagesByType(items, imageType);
  }, [items, imageType]);

  const tabItems = useMemo(() => {
    const newExtendedImageTypes = { ...ExtendedImageTypes };
    if (parentType === 'plot') {
      delete newExtendedImageTypes['100'];
      delete newExtendedImageTypes['400'];
    }
    return Object.entries(newExtendedImageTypes).map(([key, value]) => {
      const itemsLenght = filterImagesByType(items, key).length;
      const label = (
        <>
          {value}
          <sup style={{ paddingLeft: '3px', marginTop: '-5px' }}> {itemsLenght}</sup>
        </>
      );
      return <Tab label={label} value={key} className={classes.tab} />;
    });
  }, [classes.tab, items, parentType]);

  return (
    <div style={{ position: 'relative', flex: '1 1 auto' }}>
      <Tabs
        value={imageType}
        onChange={handleChangeType}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabItems}
      </Tabs>
      <div style={{ position: 'relative', height: 'calc(100% - 60px)', marginTop: '12px' }}>
        <CardsImageOrVideos
          items={filteredItems}
          menuItem={menuItem}
          isVideo={isVideo}
          onChangeItems={onChange}
          onDeleteItems={onDelete}
          record={propRecord}
          hasButtons={!disabled}
        />
        <ImageOrVideoDialog
          title={isVideo ? 'Редактирование видео' : 'Редактирование изображения'}
          changeOpenRef={changeOpenRef}
          onChange={onChange}
          record={record}
          isVideo={isVideo}
          hasTemplateName={hasTemplateName}
        />
      </div>
    </div>
  );
};

export const ListImagesOrVideos = ({
  record,
  isVideo,
  hasMultiple = false,
  parentType = '',
  hasTemplateName = false,
  disabled,
}) => {
  const [imageType, setImageType] = useState(Object.keys(ExtendedImageTypes)[0]);

  const key = useMemo(() => {
    return isVideo ? 'videos' : 'images';
  }, [isVideo]);

  const [values, onChangeValues, onDeleteValues] = useAdditionalTableValue(record, key);
  const classes = useStyles();

  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const itemsLength = useMemo(() => {
    return values.length;
  }, [values.length]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.header_left}>
          <span className={classes.title}>
            {isVideo ? `Видео` : `Изображения`}
            <sup> {itemsLength}</sup>
          </span>
        </div>
        <div className={classes.header_right}>
          {!isVideo && (
            <div className={classes.header_right_row}>
              <DownloadImages images={record.images} />
            </div>
          )}
          {!disabled && (
            <div className={classes.header_right_row}>
              <AddButton onClick={handleOpenDialog} />
            </div>
          )}
          <ImageOrVideoDialog
            title={isVideo ? 'Создание видео' : 'Создание изображения'}
            changeOpenRef={changeOpenRef}
            onChange={onChangeValues}
            isVideo={isVideo}
            items={values}
            imageType={imageType}
            hasTemplateName={hasTemplateName}
          />
          {hasMultiple && !disabled && (
            <div className={classes.header_right_row}>
              <MultipleAddImages onChange={onChangeValues} items={values} imageType={imageType} />
            </div>
          )}
        </div>
      </div>
      <TableImagesOrVideo
        items={values}
        onChange={onChangeValues}
        onDelete={onDeleteValues}
        isVideo={isVideo}
        record={record}
        imageType={imageType}
        setImageType={setImageType}
        parentType={parentType}
        hasTemplateName={hasTemplateName}
        disabled={disabled}
      />
    </div>
  );
};
