import axios from 'axios';
import { checkType, cropObjectDepth, isObject } from './utils';
import { getOidc, logout } from './authProvider';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(undefined, async (error) => {
  const responseMessage =
    error.response?.data?.Error?.Message || error.response?.data?.error?.message;
  if (responseMessage) {
    error.message = responseMessage;
  }
  if (
    error.response.status === 401 &&
    !error?.config?.url.includes('https://marketing-stats.villagio-realty.ru')
  ) {
    logout();
  }
  throw error;
});

export const execute = ({
  method,
  params = {},
  path,
  data = {},
  url = '',
  headers,
  responseType = 'json',
  hasToken,
}) => {
  let currentHeaders = { 'Content-Type': 'application/x-www-form-urlencoded', ...headers };

  let token = '';
  const oidc = getOidc();

  if (oidc) {
    token = oidc.access_token;
  }

  if (hasToken || path) {
    currentHeaders = {
      ...currentHeaders,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }

  if (path) {
    const firstFive = path.slice(0, 5);

    if (firstFive === 'me' || firstFive === 'users' || firstFive === 'roles') {
      url = `${process.env.REACT_APP_AUTH_API_URL}${path}`;
    } else if (path.includes('page-preview')) {
      url = `${process.env.REACT_APP_SITE_HOST}${path}`;
    } else {
      url = `${process.env.REACT_APP_STORAGE_API_URL}${path}`;
    }
  }

  return axiosInstance({
    method,
    params,
    data,
    url,
    headers: currentHeaders,
    responseType,
  });
};

const getPath = ({ resource, id, parentResource }) => {
  return `${parentResource ? `${parentResource.resource}/${parentResource.id}/` : ''}${resource}${
    id ? `/${id}` : ''
  }`;
};

const getPathAddtinional = (resource, id, additionalResource, additionalId) => {
  let path = `${resource}/${id}`;
  if (additionalResource) {
    path += `/${additionalResource}`;
  }
  if (additionalId) {
    path += `/${additionalId}`;
  }
  return path;
};

export const getOriginalRelativeData = (data) => {
  const newData = { ...data };

  Object.keys(data).forEach((key) => {
    if (isObject(data[key]) && 'isMultipleRelations' in data[key]) {
      newData[key] = data[key].current;
    }
  });

  return newData;
};

// закидывать по пути существующего объекта ПУТ запрос с датой где ЦЕЛЫЙ объект но с новыми полями
const fetch = async (method, path, data) => {
  await execute({
    method,
    path,
    data,
  });
};

function getRandomString() {
  return Math.random().toString(36).slice(8);
}

const getRelativeRequests = (data, resource, id, isMultiple, previousData) => {
  const additionalRequest = [];

  Object.keys(data).forEach((key) => {
    if (isObject(data[key]) && 'isMultipleRelations' in data[key]) {
      const items = data[key];
      let newItems = items.new;
      const currentItems = items.current;
      const additionalResource = key;

      if (isMultiple && previousData && previousData[key]?.length > 0) {
        const newItemsMultipleEdit = [];
        const prevItems = previousData[key];

        let resourceId = '';
        Object.keys(prevItems[0]).forEach((itemKey) => {
          const lowerItemKey = itemKey.toLowerCase();
          if (
            lowerItemKey.includes(`${key.substr(0, key.length - 1).toLowerCase()}`) &&
            lowerItemKey.includes(`id`)
          ) {
            resourceId = itemKey;
          }
        });

        prevItems.forEach((prevItem) => {
          if (
            currentItems.findIndex((currentItem) => {
              return currentItem.id === prevItem[resourceId];
            }) === -1
          ) {
            newItemsMultipleEdit.push({ ...prevItem, clientStatus: 'delete' });
          }
        });

        currentItems.forEach((currentItem) => {
          if (
            prevItems.findIndex((prevItem) => {
              return currentItem.id === prevItem[resourceId];
            }) === -1
          ) {
            newItemsMultipleEdit.push({ ...currentItem, clientStatus: 'create' });
          }
        });

        newItems = newItemsMultipleEdit;
      }

      newItems.forEach((newItem) => {
        const status = newItem.clientStatus;
        let method = 'post';
        let path = getPathAddtinional(resource, id, additionalResource);
        if (status === 'create') {
          delete newItem.id;
        } else if (status === 'delete') {
          method = 'delete';
          path = getPathAddtinional(resource, id, additionalResource, newItem.id);
        } else if (status === 'edit') {
          method = 'put';
          path = getPathAddtinional(resource, id, additionalResource, newItem.id);
        } else {
          alert('что то пошло не так...');
          return;
        }

        additionalRequest.push(fetch(method, path, cropObjectDepth(newItem)));
      });
      data[key] = currentItems;
    }
  });

  return additionalRequest;
};

const sortingMap = {
  ContractCancelReasons: 300,
  ClientTouches: 401,
  Taasks: 1600,
  'Taasks/ForObject': 1600,
  RealtyObjects: null,
  users: 301,
};

export const dataProvider = {
  getList: async (resource, { pagination = { page: 1, perPage: 20 }, filter } = {}) => {
    const params = {
      FlatDetails: 300,
      RelDetails: 100,
      iswithstats: true,
      IsTaaskStats: true,
      isWithClient: true,
      isWithEmployee: true,
      SortBy: resource in sortingMap ? sortingMap[resource] : 501,
      ...filter,
    };

    if (
      resource === 'Peoples' &&
      ('OwnerInRealtySectorIds' in filter || 'OwnerInRealtyGroupIds' in filter)
    ) {
      resource = 'Peoples/withRealty';
    }

    if (resource === 'ClientTouches') {
      params.isWithInitialRealtyObject = true;
    }

    if (
      resource === 'cmsAdmin/magazineMainPages' ||
      resource === 'cmsAdmin/magazineCategoryPages' ||
      resource === 'cmsAdmin/magazineArticlePages'
    ) {
      resource = 'cmsAdmin/pages';
    }

    if (resource === 'Clients') {
      resource = 'Peoples';
    }

    if (resource === 'Instructions') {
      resource = 'FreeLists';
      params.ListType = 'Instruction';
    }

    if (resource === 'OurNews') {
      resource = 'FreeLists';
      params.ListType = 'OurNews';
    }

    if (resource === 'Taasks') {
      params.RelDetails = 300;
    }

    // if (resource === 'RealtyBaseObjects') {
    //   params.RelDetails = 300;
    // }

    if (resource === 'Articles') {
      params.FlatDetails = 200;
    }

    if (resource === 'RealtyGroups') {
      params.isGeoSeedParents = true;
      params.isGeoSeedChildren = true;
    }

    if (resource === 'RealtyObjects') {
      params.isWithBaseObject = true;
    }

    params.Quantity = true;

    params.Skip = (pagination.page - 1) * pagination.perPage;
    params.Take = pagination.perPage;

    const response = await execute({
      method: 'get',
      params,
      path: `${resource}`,
    });
    const { data = [], quantity, count } = response.data;

    let total;
    if (quantity != null) {
      total = quantity;
    } else {
      total = count;
    }

    return { data, total };
  },
  getOne: async (resource, { id }) => {
    if (resource === 'Clients') {
      resource = 'Peoples';
    }

    if (resource === 'FakeContracts') {
      resource = 'Contracts';
    }

    if (
      resource === 'cmsAdmin/magazineMainPages' ||
      resource === 'cmsAdmin/magazineCategoryPages' ||
      resource === 'cmsAdmin/magazineArticlePages'
    ) {
      resource = 'cmsAdmin/pages';
    }

    if (resource === 'Instructions' || resource === 'OurNews') {
      resource = 'FreeLists';
    }

    const params = {
      FlatDetails: 300,
      RelDetails: 300,
      IsWithStats: true,
      Status: 400,
    };

    if (resource === 'GeoSeeds' || resource === 'RealtyGroups') {
      params.isGeoTags = true;
      params.isGeoSeedParents = true;
      params.isGeoSeedChildren = true;
    }

    if (resource === 'RealtyObjects') {
      params.isWithBaseObject = true;
    }

    const response = await execute({
      method: 'get',
      path: `${resource}/${id}`,
      params,
    });

    const { data } = response;

    if (resource === 'users') {
      return { data };
    }

    return data;
  },
  getMany: async () => {},
  getManyReference: async () => {},
  update: async (resource, { id, data, previousData, isMultiple }, parentResource) => {
    data.anyJsonDictionary = {
      ...data.anyJsonDictionary,
      updateDate: new Date().getTime(),
    };

    if (resource === 'Clients') {
      resource = 'Peoples';
    }

    if (resource === 'FakeContracts') {
      resource = 'Contracts';
    }

    if (
      resource === 'cmsAdmin/magazineMainPages' ||
      resource === 'cmsAdmin/magazineCategoryPages' ||
      resource === 'cmsAdmin/magazineArticlePages'
    ) {
      resource = 'cmsAdmin/pages';
    }

    if (resource === 'Instructions' || resource === 'OurNews') {
      resource = 'FreeLists';
    }

    try {
      await Promise.all(getRelativeRequests(data, resource, id, isMultiple, previousData));
    } catch (error) {
      alert(error);
    }

    const params = { FlatDetails: 300, RelDetails: 300 };

    if (resource === 'RealtyObjects') {
      params.isWithBaseObject = true;
    }

    if (resource === 'Taasks') {
      params['ForceRelUpdates'] = true;
      if (
        data.taaskObjects?.length > 0 &&
        previousData.taaskObjects?.length > 0 &&
        data.taaskObjects[0].taaskObjectType === previousData.taaskObjects[0].taaskObjectType
      ) {
        data.taaskObjects.forEach((dataItem) => {
          const foundItem = previousData.taaskObjects.find((previousDataItem) => {
            return previousDataItem.taaskObjectId === dataItem.taaskObjectId;
          });
          if (foundItem) {
            dataItem.id = foundItem.id;
          }
        });
      }
    }

    const response = await execute({
      method: 'put',
      path: getPath({ resource, id, parentResource }),
      params,
      data: cropObjectDepth(data, 3),
    });

    if (resource === 'users') {
      return { data: response.data };
    }

    return response.data;
  },
  updateMany: async (resource, data, updatedData) => {
    const promises = data.map((item) => {
      const newData = { ...item, ...updatedData };
      return dataProvider.update(resource, {
        id: item.id,
        data: newData,
        previousData: item,
        isMultiple: true,
      });
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  },
  create: async (resource, { data, params = {} }, parentResource, path) => {
    const originalData = getOriginalRelativeData(data);

    if (resource === 'Instructions' || resource === 'OurNews') {
      resource = 'FreeLists';
    }

    if (resource === 'Clients') {
      resource = 'Peoples';
    }

    if (
      resource === 'cmsAdmin/magazineMainPages' ||
      resource === 'cmsAdmin/magazineCategoryPages' ||
      resource === 'cmsAdmin/magazineArticlePages'
    ) {
      resource = 'cmsAdmin/pages';
    }

    const requestOptions = {
      method: 'post',
      path: path || getPath({ resource, parentResource }),
      data: cropObjectDepth(originalData, 3),
      params,
    };

    if (resource === 'Taasks') {
      requestOptions.params.ForceRelUpdates = true;
      requestOptions.data.taaskStatus = 200;
    }

    // Костыль чтобы не передавать имя

    if (resource === 'Demos' || resource === 'Taasks' || resource === 'RealtyBaseObjects') {
      requestOptions.data.name = '.';
    }

    // Костыль чтобы не передавать имя

    const response = await execute(requestOptions);

    try {
      await Promise.all(getRelativeRequests(data, resource, response.data.data.id));
    } catch (error) {
      alert(error);
    }

    return response.data;
  },
  delete: async (resource, { id, previousData }, parentResource) => {
    if (resource === 'Instructions' || resource === 'OurNews') {
      resource = 'FreeLists';
    }

    if (resource === 'Clients') {
      resource = 'Peoples';
    }

    if (
      resource === 'cmsAdmin/magazineMainPages' ||
      resource === 'cmsAdmin/magazineCategoryPages' ||
      resource === 'cmsAdmin/magazineArticlePages'
    ) {
      resource = 'cmsAdmin/pages';
    }

    const data = {
      ...previousData,
      slug: `deleted-${previousData.slug}-${getRandomString()}`,
      name: `deleted-${previousData.name}-${getRandomString()}`,
    };

    Object.keys(data).forEach((newDataKey) => {
      const type = checkType(data[newDataKey]);
      if (type === 'Object' || type === 'Array') {
        delete data[newDataKey];
      }
    });

    const response = await execute({
      method: 'delete',
      path: getPath({ resource, id, parentResource }),
      data: cropObjectDepth(data),
    });

    return response;
  },
  deleteMany: async () => {},
  search: async (resource, params = {}) => {
    const { filter, pagination, ...otherParams } = params;

    // eslint-disable-next-line no-useless-catch
    try {
      return await dataProvider.getList(resource, {
        filter: { FlatDetails: 100, RelDetails: 100, ...filter },
        pagination: { perPage: 300, page: 1, ...pagination },
        ...otherParams,
      });
    } catch (error) {
      throw error;
    }
  },
  existPresentation: async (url) => {
    try {
      await execute({
        url,
        method: 'head',
      });

      return true;
    } catch (error) {
      return false;
    }
  },
  addImages: async ({ id, resource, additionalResource }, images) => {
    const result = await execute({
      path: getPathAddtinional(resource, id, additionalResource),
      method: 'post',
      data: images,
    });

    return result.data;
  },
  getAdditionalList: async (
    resource,
    { pagination = { page: 1, perPage: 300 }, filter = {}, ...otherParams } = {},
  ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await dataProvider.getList(resource, {
        filter: { FlatDetails: 300, RelDetails: 100, isPeopleTaaskStats: true, ...filter },
        pagination,
        ...otherParams,
      });
    } catch (error) {
      throw error;
    }
  },
  addComment: async (comment) => {
    try {
      return await execute({
        path: 'Comments',
        method: 'post',
        data: comment,
      });
    } catch (error) {
      console.log(error);
    }
  },
  editComment: async (data) => {
    try {
      return await execute({
        path: 'Comments',
        method: 'put',
        data,
      });
    } catch (error) {
      console.log(error);
    }
  },
  getCallRecord: async (id) => {
    try {
      return await execute({
        path: `clientTouches/${id}/record`,
        method: 'get',
        responseType: 'blob',
      });
    } catch (error) {
      console.log(error);
    }
  },
  getEnums: async () => {
    try {
      return await execute({
        path: `staticEnums`,
        method: 'get',
      });
    } catch (error) {
      console.log(error);
    }
  },
  getNotificationClients: async () => {
    try {
      return await dataProvider.getList('Peoples', {
        pagination: { page: 1, perPage: 300 },
        filter: { SortBy: 1500, ProcessStatuses: '100' },
      });
    } catch (error) {
      console.log(error);
    }
  },
  exportCSV: async ({ resource, filter }) => {
    let additionalPath = '';
    if (
      resource === 'Peoples' &&
      ('OwnerInRealtySectorIds' in filter || 'OwnerInRealtyGroupIds' in filter)
    ) {
      additionalPath = '/withRealty';
    }

    if (resource === 'Clients') {
      resource = 'Peoples';
    }

    // eslint-disable-next-line no-useless-catch
    try {
      return await execute({
        path: `${resource}/csv${additionalPath}`,
        method: 'get',
        responseType: 'blob',
        params: {
          FlatDetails: 300,
          RelDetails: 100,
          Take: 10000,
          isgzipped: true,
          ...filter,
        },
      });
    } catch (error) {
      throw error;
    }
  },
  exportOwnersCSV: async ({ filter }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await execute({
        path: `Comments/realtyOwners/excel`,
        method: 'get',
        responseType: 'blob',
        params: {
          // isGzipped: true,
          ...filter,
        },
      });
    } catch (error) {
      throw error;
    }
  },
  getImportantObjects: async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await dataProvider.getList('RealtyObjects', {
        filter: { FlatDetails: 300, RelDetails: 100, isPeopleTaaskStats: true, isImportant: true },
        pagination: { page: 1, perPage: 300 },
      });
    } catch (error) {
      throw error;
    }
  },
  mergePeoples: async ({ fromUserId, toUserId, data }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await execute({
        path: `peoples/${fromUserId}/mergeTo/${toUserId}`,
        method: 'put',
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  getInstructions: async () => {
    return dataProvider.getList('FreeLists', {
      filter: { FlatDetails: 300, RelDetails: 100, ListType: 'Instruction' },
      pagination: { page: 1, perPage: 300 },
    });
  },
  getArticlePreview: async (data) => {
    try {
      return await execute({
        path: `api/magazine/page-preview`,
        method: 'post',
        responseType: 'blob',
        data,
      });
    } catch (error) {
      console.log(error);
    }
  },
  addContactCalling: async (data) => {
    try {
      return await execute({
        path: 'Taasks/TotalRecall',
        method: 'post',
        data,
      });
    } catch (error) {
      console.log(error);
    }
  },
  getMarketingStats: async (id) => {
    try {
      return await execute({
        url: `https://marketing-stats.villagio-realty.ru/api/v1/realty-object/${id}`,
        method: 'get',
        hasToken: true,
      });
    } catch (error) {
      console.log(error);
    }
  },
  geocodeYandexMapCoordinates: async (coordinates) => {
    try {
      return await execute({
        url: `https://geocode-maps.yandex.ru/1.x/?apikey=d30a30a9-50d3-4d24-8a56-a3cb8ff15401&geocode=${coordinates}&format=json`,
        method: 'get',
      });
    } catch (error) {
      console.log(error);
    }
  },
  suggestYandexMap: async (search) => {
    try {
      return await execute({
        url: `https://suggest-maps.yandex.ru/v1/suggest?text=${search}&apikey=c2037de3-e123-4064-8449-f7228381b3e4`,
        method: 'get',
      });
    } catch (error) {
      console.log(error);
    }
  },
  getMe: async () => {
    try {
      return await execute({
        path: 'me',
        method: 'get',
      });
    } catch (error) {
      console.log(error);
    }
  },
};
