import React from 'react';
import { Route } from 'react-router-dom';
import { currentProfile, currentUser } from './authProvider';

import { AnalyticsPage, KanbanPage, LoadingPage, CalendarPage } from './pages';
import { checkRoleAdminOrSupervisorOrOdp, getToday, getTomorrow } from './utils';
import { ContractsKanbanPage } from './pages/ContractsKanban';

const customRoutes = [
  <Route
    exact
    path="/oidc-callback"
    render={() => {
      return <LoadingPage />;
    }}
  />,
  <Route
    exact
    path="/no-rights"
    render={() => {
      return 'У вас нет прав... Обратитесь к администратору! ';
    }}
  />,
  <Route
    exact
    path="/no-people"
    render={() => {
      return 'У вас не привязан пипл к логину! Необходимо привязать пипла к логину для корректной работы админки! Обратитесь к администратору!';
    }}
  />,
  <Route
    exact
    path="/oidc-silent-callback.html"
    render={() => {
      return null;
    }}
  />,
  <Route
    exact
    path="/loading"
    render={() => {
      return <LoadingPage />;
    }}
  />,
  <Route
    exact
    path="/analytics"
    render={() => {
      return <AnalyticsPage />;
    }}
  />,
  <Route
    exact
    path="/calendar"
    render={() => {
      return <CalendarPage />;
    }}
  />,
  <Route
    exact
    path="/Taasks/Kanban"
    render={() => {
      const items = [
        {
          title: 'Просроченные',
          filter: { IsCalcOutdated: true },
          id: 1,
        },
        {
          title: 'На сегодня',
          filter: {
            PlannedDateMin: getToday()?.start.toISOString(),
            PlannedDateMax: getToday()?.end.toISOString(),
          },
          id: 2,
        },
        {
          title: 'На завтра',
          filter: {
            PlannedDateMin: getTomorrow()?.start.toISOString(),
            PlannedDateMax: getTomorrow()?.end.toISOString(),
          },
          id: 3,
        },
      ];

      const isAdmin = checkRoleAdminOrSupervisorOrOdp(currentProfile?.role);

      const filter = {
        IsCompleted: false,
      };

      if (!isAdmin) {
        filter.AssignedBoth = String(currentUser?.id);
      }

      return (
        <KanbanPage
          items={items}
          defaultFilter={filter}
          basePath="Taasks"
          type="task"
          buttonTitle="Создать задачу"
        />
      );
    }}
  />,
  <Route
    exact
    path="/Demos/Kanban"
    render={() => {
      const items = [
        {
          title: 'Назначен',
          filter: { DemoCalcStatus: 100 },
          id: 1,
        },
        {
          title: 'Проведён',
          filter: { DemoCalcStatus: 200 },
          id: 2,
        },
        {
          title: 'Просрочен',
          filter: { DemoCalcStatus: 300 },
          id: 3,
        },
      ];

      const isAdmin = checkRoleAdminOrSupervisorOrOdp(currentProfile?.role);

      const filter = {};

      if (!isAdmin) {
        filter.ExpertId = String(currentUser?.id);
      }

      return (
        <KanbanPage
          defaultFilter={filter}
          items={items}
          basePath="Demos"
          type="show"
          buttonTitle="Создать показ"
        />
      );
    }}
  />,
  <Route
    exact
    path="/Contracts/Kanban"
    render={() => {
      return <ContractsKanbanPage />;
    }}
  />,
];

export default customRoutes;
