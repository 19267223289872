export const taskStatusFilledColors = { 300: 'green', 400: 'green' };
export const taskImportanceFilledColors = { 100: 'red', 400: 'green' };
export const sysStatusFilledColors = { 100: 'red', 200: 'green' };
export const contractStatusFilledColors = {
  10000: 'green',
  20000: 'red',
  20050: 'red',
  20100: 'red',
  20200: 'red',
};

export const showStatusFilledColors = {
  300: 'green',
  400: 'red',
  500: 'red',
  600: 'red',
};

export const showCalcStatusFilledColors = {
  200: 'green',
  300: 'red',
};

export const clientTouchStatusFilledColors = {
  true: 'green',
  false: 'red',
};

export const clientTouchTypeFilledColors = {
  100: 'green',
  200: 'green',
  300: 'green',
  1000: 'red',
  1100: 'red',
  1200: 'red',
  1300: 'red',
  1400: 'red',
  1500: 'red',
  1600: 'red',
  1700: 'red',
  1800: 'red',
  1900: 'red',
  10000: 'red',
};

export const clientProcessStatusFilledColors = {
  100: 'red',
  300: 'green',
};
