import axios from 'axios';

const staticUrl = process.env.REACT_APP_STATIC_API_URL;

export const getImageOrVideoPath = ({
  width,
  height,
  path,
  type = '',
  params = '',
  isVideo,
  isFile,
}) => {
  if (isFile) {
    return `${staticUrl}files/${path}`;
  }
  if (isVideo) {
    return `${staticUrl}video/${path}`;
  }
  return `${staticUrl}cache-new/${width}x${height}/${path}${type}${params}`;
};

const execute = ({ method, params = {}, path, data, responseType }) => {
  const url = `${staticUrl}${path}`;

  return axios({
    method,
    params,
    data,
    url,
    responseType,
    headers: {
      Authorization: `Bearer ${
        JSON.parse(
          localStorage.getItem(
            `oidc.user:${process.env.REACT_APP_AUTH_HOST}:${process.env.REACT_APP_AUTH_CLIENT_ID}`,
          ),
        )?.access_token
      }`,
    },
  });
};

export const staticProvider = {
  uploadResizable: async (data) => {
    const result = await execute({
      method: 'post',
      path: 'api/v1/resizable',
      data,
    });

    return result;
  },

  uploadVideo: async (data) => {
    const result = await execute({
      method: 'post',
      path: 'api/v1/video',
      data,
    });

    return result;
  },

  uploadFile: async (data) => {
    const result = await execute({
      method: 'post',
      path: 'api/v1/files',
      data,
    });

    return result;
  },

  downloadImage: async (path, type = '') => {
    const result = await execute({
      method: 'get',
      path: `cache-new/1500x1500/${path}${type}`,
      responseType: 'blob',
    });

    return result;
  },
};
