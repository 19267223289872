import React, { useMemo } from 'react';
import { FormTab } from 'react-admin';

import { FormRow } from '../../FormRow';
import { TextInput } from '../../TextInput/TextInput';
import { AutocompleteWithFormRow, ConstantSelect } from '../../app';
import { TabbedForm } from '../../common';
import { validates } from '../../../utils';
import {
  communicationTypes,
  publicationStatuses,
  realtyGroupCategories,
  taskObjectTypes,
} from '../../../constants';
import { FormCard } from '../../FormCard';

export const FormEnum = (props) => {
  const { id, record, resource } = props;

  const disableEdit = useMemo(() => {
    if (record?.isPredefined) {
      return true;
    }

    return false;
  }, [record.isPredefined]);

  return (
    <TabbedForm {...props} hideSave={disableEdit}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="ID" disabled />
            </FormRow>
          )}
          {resource !== 'RealtyLiquidityReason' &&
            resource !== 'ClientTags' &&
            resource !== 'FeedTargets' &&
            resource !== 'TaaskResults' &&
            resource !== 'UserActivityTypes' &&
            resource !== 'InterestSources' &&
            resource !== 'GeoSeedTypes' &&
            resource !== 'ContractCancelReasons' &&
            resource !== 'RequestChannels' &&
            resource !== 'SalesDirections' &&
            resource !== 'TaaskTags' &&
            resource !== 'TaaskTypes' &&
            resource !== 'ContractTags' &&
            resource !== 'RealtyObjectTags' &&
            resource !== 'RealtyObjectPayments' &&
            resource !== 'cmsAdmin/pageTags' &&
            resource !== 'cmsAdmin/pageTypes' &&
            resource !== 'cmsAdmin/widgetTags' &&
            resource !== 'cmsAdmin/widgetTemplateTypes' &&
            resource !== 'RealtyObjectCommunications' && (
              <FormRow>
                <TextInput source="slug" label="Слаг" validate={validates.slug} />
              </FormRow>
            )}
          <FormRow>
            <TextInput source="name" label="Имя" validate={validates.name} />
          </FormRow>
          {resource === 'GeoSeedTypes' && (
            <FormRow>
              <TextInput source="shortName" label="Краткое имя" validate={validates.required} />
            </FormRow>
          )}
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow isLast>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={200}
            />
          </FormRow>
          {resource === 'RealtyObjectCommunications' && (
            <FormRow>
              <ConstantSelect
                source="communicationType"
                objectChoices={communicationTypes}
                label="Тип коммуникации"
                validate={validates.communicationType}
                allowEmpty
              />
            </FormRow>
          )}
          {resource === 'RealtyGroupTypes' && (
            <>
              <FormRow>
                <ConstantSelect
                  source="realtyGroupCategory"
                  objectChoices={realtyGroupCategories}
                  label="Категория"
                  allowEmpty
                />
              </FormRow>
              <AutocompleteWithFormRow
                {...props}
                source="realtyObjectTypes"
                resourceName="RealtyObjectTypes"
                resourceId="realtyObjectTypeId"
                additionalSource="realtyObjectType"
                componentProps={{
                  label: 'Тип объекта',
                  multiple: true,
                  filterSelectedOptions: true,
                }}
              />
            </>
          )}
          {resource === 'TaaskTypes' && (
            <FormRow>
              <ConstantSelect
                source="taaskObjectType"
                objectChoices={taskObjectTypes}
                label="Тип объекта"
                allowEmpty
              />
            </FormRow>
          )}
          {resource === 'TaaskResults' && (
            <AutocompleteWithFormRow
              {...props}
              source="taaskType"
              resourceName="TaaskTypes"
              resourceId="taaskTypeId"
              componentProps={{
                label: 'Тип задачи',
              }}
              validate={validates.required}
            />
          )}
        </FormCard>
      </FormTab>
    </TabbedForm>
  );
};
