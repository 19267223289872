import React, { useCallback } from 'react';
import {
  useRedirect,
  useRefresh,
  useNotify,
  Datagrid,
  TextField,
  DateField,
  useDelete,
  useCreate,
} from 'react-admin';
// import { DataGrid } from '@material-ui/data-grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {
  makeStyles,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  IconButton,
  Chip,
} from '@material-ui/core';
import { ListBase } from '../../../components/ListBase';

import {
  EditIcon,
  // eslint-disable-next-line no-unused-vars
  DeleteIcon,
  UsersVilagioIcon,
  UsersEmailConfirmedIcon,
} from '../../../components/Icons';
import { convertLoginToPeople } from '../../../utils';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal',
    },
  },
  menuItem: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',

    '&:last-child': {
      borderTop: '1px solid #E0E0E0',
    },

    '& svg': {
      marginRight: 15,
    },
  },
  menuListWrapper: {
    zIndex: 10,
  },
  cellName: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#1E1E1E',
    fontWeight: '500',

    '& svg': {
      float: 'left',
      marginRight: '6px',
      color: '#A71930',
    },
  },
  cellEmail: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: '6px',
    },
  },
  authorCell: {
    lineHeight: '18px',
  },
  authorCellValue: {
    display: 'block',
  },
  authorCellModifedDate: {
    display: 'block',
    color: '#9E9E9E',
    fontSize: '11px',
  },
  statusCell: {
    display: 'block',
    padding: '8px 12px',
    background: '#E9F5EA',
    borderRadius: '3px',
    color: '#50AE54',
    fontSize: '14px',
    textAlign: 'center',
  },
  statusCellInactive: {
    color: '#F2443D',
    background: '#FFEBEE',
  },
  drawer: {
    padding: '25px 0',
  },
  drawerTitle: {
    padding: '0 25px',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#1E1E1E',
    marginBottom: '25px',
  },
  chip: {
    marginRight: '4px',
    marginBottom: '4px',
  },
}));

const NameCell = (props) => {
  const { record } = props;
  const classes = useStyles();

  return (
    <div className={classes.cellName}>
      {record.isStaff && <UsersVilagioIcon />}
      {record.displayName}
    </div>
  );
};

const EmailCell = (props) => {
  const { record } = props;
  const classes = useStyles();

  return (
    <div className={classes.cellEmail}>
      {record.email}
      {record.emailConfirmed && <UsersEmailConfirmedIcon />}
    </div>
  );
};

export const MenuCell = ({ resource, record }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = (event) => {
    anchorRef.current = event.currentTarget;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && event && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const classes = useStyles();

  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleEdit = useCallback(() => {
    redirect(`/${resource}/${record.id}`);
  }, [record.id, redirect, resource]);

  // const [updateOne] = useUpdate(resource, record.id, null, null, {
  //   onSuccess: () => {
  //     refresh();
  //   },
  //   onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
  // });

  const [createPerson] = useCreate(
    'Peoples',
    {},
    {
      onSuccess: () => {
        refresh();
      },
      onFailure: (error) => {
        if (error.response.status === 412) {
          return notify(`Error: Этот пользователь уже добавлен`, 'warning');
        }

        return notify(`Error: ${error.message}`, 'warning');
      },
    },
  );

  // const handleChangeStatus = useCallback(async () => {
  //   let diff = { ...record, isActive: !record.isActive };
  //   updateOne({ payload: { data: diff } });
  //   handleClose();
  // }, [record]);

  const handleAddToPeoples = useCallback(() => {
    const newPerson = convertLoginToPeople(record);
    createPerson({ payload: { data: newPerson } });
    handleClose();
  }, [createPerson, record]);

  const [deleteOne] = useDelete(resource, record.id, record, {
    onSuccess: () => {
      refresh();
    },
    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
  });

  // eslint-disable-next-line no-unused-vars
  const handleDelete = useCallback(() => {
    deleteOne({ payload: { data: record } });
  }, [deleteOne, record]);

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="add an alarm"
        onClick={handleToggle}
        aria-describedby="test"
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className={classes.menuListWrapper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="MenuListSets" onKeyDown={handleListKeyDown}>
                  <MenuItem classes={{ root: classes.menuItem }} onClick={handleEdit}>
                    <EditIcon width={24} height={24} /> Редактировать
                  </MenuItem>
                  {/* <MenuItem classes={{ root: classes.menuItem }} onClick={handleChangeStatus}>
                    <SoldStatusIcon width={18} height={24} /> Сменить статус
                  </MenuItem> */}
                  <MenuItem classes={{ root: classes.menuItem }} onClick={handleAddToPeoples}>
                    <EditIcon width={24} height={24} /> Добавить в пользователи
                  </MenuItem>
                  {/* <MenuItem classes={{ root: classes.menuItem }} onClick={handleDelete}>
                    <DeleteIcon width={22} height={24} /> Удалить
                  </MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const StatusCell = ({ record, source }) => {
  const classes = useStyles();

  const isActive = record[source];

  return (
    <span className={`${classes.statusCell} ${!isActive && classes.statusCellInactive}`}>
      {isActive ? 'Активен' : 'Не активен'}
    </span>
  );
};

const RolesCell = ({ record, source }) => {
  const classes = useStyles();
  const roles = record[source];

  if (!roles) {
    return null;
  }

  const rolesItems = roles.map((role) => {
    return <Chip className={classes.chip} variant="outlined" label={role.displayName} />;
  });

  return rolesItems;
};

const TableLogins = () => {
  return (
    <Datagrid hasBulkActions>
      <NameCell source="displayName" label="Имя" sortable={false} />
      <TextField source="userName" label="Логин" sortable={false} />
      <EmailCell source="email" label="Эл. почта" sortable={false} />
      <RolesCell source="roles" label="Роли" sortable={false} />
      <StatusCell source="isActive" label="Статус" sortable={false} />
      <DateField
        source="dateLoggedIn"
        label="Был онлайн"
        sortable={false}
        locales="ru"
        options={{
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }}
      />
      <MenuCell />
    </Datagrid>
  );
};

export const ListLogins = (props) => {
  return (
    <ListBase
      {...props}
      filter={{ active: false }}
      hasSearchVariants={false}
      searchBy="Search"
      hasFilters={false}
    >
      <TableLogins />
    </ListBase>
  );
};
