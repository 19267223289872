import { maxLength, minLength, required, regex, minValue, maxValue } from 'react-admin';
import { urlRegExp } from './validate';

const checkHasValuesAutocomplete = (value) => {
  if (Array.isArray(value)) {
    return Boolean(value.length);
  }

  return Boolean(value.current.length);
};

const checkHasGoodInValuesAutocomplete = (values) => {
  if (Array.isArray(values)) {
    return values.some((value) => value.isGood);
  }

  return values.current.some((value) => value.isGood);
};

export const validates = {
  slug: [required(), maxLength(50), regex(/^[a-zA-Z0-9+_-]+$/)],
  name: [required(), maxLength(150)],
  feePercents: [
    minValue(0),
    required(),
    (value) => {
      if (value && !/^\d*(\.\d{1,2})?$/i.test(String(value))) {
        return 'Должно быть в формате: 23, 23.33, 23.1. Не больше 2 знаков после запятой.';
      }

      return undefined;
    },
  ],
  nameObject: [required(), maxLength(150), minLength(9)],
  friendlyId: maxLength(50),
  shortName: maxLength(50),
  shortNameObject: [maxLength(50), minLength(9)],
  introduction: maxLength(1024),
  landCadastralNo: maxLength(20),
  shortDescription: maxLength(512),
  shortDescriptionObject: maxLength(160),
  pdfDescriptionObject: maxLength(1600),
  seoTitle: maxLength(255),
  seoDescription: maxLength(800),
  objectImage: required(),
  infrastructures: required(),
  objectVideo: [required(), maxLength(1024)],
  objectVillage: required(),
  mainClient: required(),
  contract: required(),
  soldWith: required(),
  cancelReason: required(),
  shiftReason: required(),
  shiftDate: required(),
  soldPriceRub: required(),
  lastPreSoldDate: required(),
  lastSoldDate: required(),
  discountValue: required(),
  taskPlannedDate: required(),
  currentExpertContract: required(),
  dateShows: required(),
  expertShows: required(),
  instructionHtml: required(),
  ourNewsDate: required(),
  communicationType: required(),
  touchContract: required(),
  touchClient: required(),
  clientTouchType: required(),
  peopleName: required(),
  contarctComment: (value, allValues) => {
    if (allValues?.contractCancelReasonId === 13 && !value) {
      return 'Обязательно для заполнения';
    }

    return undefined;
  },
  description2Shows: (value, allValues) => {
    if (Number(allValues.status) === 300 && !value) {
      return 'Обязательно для заполнения';
    }

    if (allValues.result && !value) {
      return 'Обязательно для заполнения';
    }

    return undefined;
  },
  showActualDate: (value, allValues) => {
    if (allValues.result && !value) {
      return 'Обязательно для заполнения';
    }

    return undefined;
  },
  showObjectsOrVillages: (value, allValues) => {
    if (
      allValues.result &&
      !checkHasValuesAutocomplete(allValues.realtyGroups) &&
      !checkHasValuesAutocomplete(allValues.realtyObjects)
    ) {
      return 'Необходимо добавить один объект или обзорную экскурсию!';
    }

    if (
      (Number(allValues.result) === 200 ||
        Number(allValues.result) === 300 ||
        Number(allValues.result) === 600) &&
      !checkHasGoodInValuesAutocomplete(allValues.realtyGroups) &&
      !checkHasGoodInValuesAutocomplete(allValues.realtyObjects)
    ) {
      return 'Необходимо указать какой объект или обзорная экскурсия понравились!';
    }

    return undefined;
  },
  taskResult: (value, allValues) => {
    if (!allValues?.taaskResultId && !value) {
      return 'Обязательно для заполнения';
    }

    return undefined;
  },
  lat: [minValue(-90), maxValue(90)],
  phone: [
    regex(
      /^\+\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2}(, \+\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2})*$/i,
      'Должно быть в формате: +7(999)888-77-66 или +7(999)888-77-66, +7(999)888-77-66, +7(999)888-77-66',
    ),
  ],
  link: [
    regex(
      urlRegExp,
      'Ссылка введена не корректно... Должно быть в формате: (http | https)://www.(text).(text) или (http | https)://www.(text).(text)/(text)',
    ),
  ],
  multipleName: maxLength(150),
  // magazine
  magazineNewsSubscriptionTitle: [required(), maxLength(72)],
  magazineNewsSubscriptionSubtitle: maxLength(256),
  magazineNewsSubscriptionButtonText: maxLength(16),
  magazineNewsSubscriptionPersonalDataText: maxLength(132),
  magazineNewsTitle1Title: [required(), maxLength(340)],
  magazineNewsTitle1Subtitle: maxLength(560),
  magazinePhoto: required(),
  magazineText1Title: maxLength(340),
  magazineQuoteTitle: maxLength(560),
  magazineQuoteAuthor: maxLength(72),
  magazineVideo2Link: required(),
  magazineDoubleVerticalPhotoSubtitle: maxLength(560),
  magazineObjectsSet1Title: maxLength(360),
  magazineObjectsSet1Author: maxLength(360),
  required: required(),
  leaseCommissionSize: [minValue(0), maxValue(200)],
  depositAmount: [minValue(0), maxValue(999999)],
  RCTitle: [required(), maxLength(150)],
  RCTitleText: maxLength(420),
  RCMainTitle: [required(), maxLength(150)],
  RCMainPhoto: required(),
  // magazine
};
