import React, { useState, useMemo } from 'react';
import { ArrayInput, FormTab, SimpleFormIterator } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { BooleanInput, FormTitle, RichText, TabbedForm } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect, ImageInputWithFormRow } from '../../app';
import { metricPositions, publicationStatuses, textMLinkPositions } from '../../../constants';
import { FormCard } from '../../FormCard';
import {
  ListCMSPages,
  ListImagesOrVideos,
  ListInfrastructures,
  ListObjects,
  ListPeoples,
  ListVillages,
} from '../../additionalLists';
import { ImageInput } from '../../ImageInput';

export const widgetTemplates = {
  magazine: {
    newsSubscription: (props) => {
      return (
        <>
          <ImageInputWithFormRow {...props} source="content.photo" buttonText="Добавить фото" />
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineNewsSubscriptionTitle}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.subtitle"
              label="Подзаголовок"
              validate={validates.magazineNewsSubscriptionSubtitle}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.buttonText"
              label="Текст на кнопке"
              validate={validates.magazineNewsSubscriptionButtonText}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.personalData"
              label="Текст для поля согласия на обрабутку перс. данных"
              validate={validates.magazineNewsSubscriptionPersonalDataText}
            />
          </FormRow>
        </>
      );
    },
    newsTitle1: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineNewsTitle1Title}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.subtitle"
              label="Подзаголовок"
              validate={validates.magazineNewsTitle1Subtitle}
            />
          </FormRow>
        </>
      );
    },
    horizontalPhoto: (props) => {
      return (
        <>
          <ImageInputWithFormRow
            {...props}
            source="content.photo"
            validate={validates.magazinePhoto}
            buttonText="Добавить горизонтальное фото"
          />
        </>
      );
    },
    verticalPhoto: (props) => {
      return (
        <>
          <ImageInputWithFormRow
            {...props}
            source="content.photo"
            validate={validates.magazinePhoto}
            buttonText="Добавить вертикальное фото"
          />
          <FormRow>
            <TextInput source="content.name" label="Название" />
          </FormRow>
        </>
      );
    },
    squarePhoto: (props) => {
      return (
        <>
          <ImageInputWithFormRow
            {...props}
            source="content.photo"
            validate={validates.magazinePhoto}
            buttonText="Добавить квадратное фото"
          />
          <FormRow>
            <TextInput source="content.name" label="Название" />
          </FormRow>
        </>
      );
    },
    fourPhotos: (props) => {
      return (
        <>
          <ImageInputWithFormRow
            {...props}
            source="content.photo1"
            validate={validates.magazinePhoto}
            buttonText="Добавить фото 1"
          />
          <ImageInputWithFormRow
            {...props}
            source="content.photo2"
            validate={validates.magazinePhoto}
            buttonText="Добавить фото 2"
          />
          <ImageInputWithFormRow
            {...props}
            source="content.photo3"
            validate={validates.magazinePhoto}
            buttonText="Добавить фото 3"
          />
          <ImageInputWithFormRow
            {...props}
            source="content.photo4"
            validate={validates.magazinePhoto}
            buttonText="Добавить фото 4"
          />
          <FormRow>
            <TextInput source="content.title" label="Заголовок" />
          </FormRow>
          <FormTitle>Описание</FormTitle>
          <FormRow>
            <RichText source="content.body" />
          </FormRow>
        </>
      );
    },
    text1: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineText1Title}
            />
          </FormRow>
          <FormTitle>Текст</FormTitle>
          <FormRow>
            <RichText source="content.body" />
          </FormRow>
          <FormRow>
            <TextInput source="content.link" label="Ссылка" />
          </FormRow>
          <FormRow>
            <TextInput source="content.linkName" label="Имя ссылки" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="content.position"
              objectChoices={textMLinkPositions}
              label="Расположение ссылки"
              allowEmpty
            />
          </FormRow>
        </>
      );
    },
    quote: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineQuoteTitle}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.author"
              label="Автор"
              validate={validates.magazineQuoteAuthor}
            />
          </FormRow>
        </>
      );
    },
    video1: (props) => {
      return (
        <>
          <ImageInputWithFormRow {...props} source="content.video" isVideo />
          <ImageInputWithFormRow
            {...props}
            source="content.poster"
            buttonText="Добавить постер к видео"
          />
        </>
      );
    },
    video2: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.videoLink"
              label="Ссылка на youtube"
              validate={validates.magazineVideo2Link}
            />
          </FormRow>
        </>
      );
    },
    doubleVerticalPhoto: (props) => {
      return (
        <>
          <ImageInputWithFormRow
            {...props}
            source="content.photo1"
            validate={validates.magazinePhoto}
            buttonText="Добавить фото 1"
          />
          <ImageInputWithFormRow
            {...props}
            source="content.photo2"
            validate={validates.magazinePhoto}
            buttonText="Добавить фото 2"
          />
          <FormRow>
            <TextInput
              source="content.subtitle"
              label="Заголовок"
              validate={validates.magazineDoubleVerticalPhotoSubtitle}
            />
          </FormRow>
          <FormRow>
            <TextInput source="content.authorLink" label="Ссылка на автора" />
          </FormRow>
        </>
      );
    },
    quote2: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineQuoteTitle}
            />
          </FormRow>
        </>
      );
    },
    objectsSet1: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineObjectsSet1Title}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.author"
              label="Автор подборки"
              validate={validates.magazineObjectsSet1Author}
            />
          </FormRow>
        </>
      );
    },
    objectsSet2: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineObjectsSet1Title}
            />
          </FormRow>
          <FormRow>
            <TextInput source="content.siteLink" label="Ссылка на сайт" />
          </FormRow>
        </>
      );
    },
    object: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineObjectsSet1Title}
            />
          </FormRow>
          <FormRow>
            <TextInput source="content.siteLink" label="Ссылка на сайт" />
          </FormRow>
        </>
      );
    },
    oldArticle: (props) => {
      return (
        <>
          <ImageInputWithFormRow
            {...props}
            source="content.VideoPoster"
            buttonText="Добавить постер к видео"
          />
          <ImageInputWithFormRow {...props} source="content.Video" isVideo />
          <FormRow maxWidth={800}>
            <RichText source="content.Body" hasImage hasVideo />
          </FormRow>
          <FormRow>
            <TextInput source="content.ExternalLink" label="Ссылка" fullWidth />
          </FormRow>
        </>
      );
    },
    villages: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineObjectsSet1Title}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.author"
              label="Автор подборки"
              validate={validates.magazineObjectsSet1Author}
            />
          </FormRow>
        </>
      );
    },
    experts: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.title"
              label="Заголовок"
              validate={validates.magazineObjectsSet1Title}
            />
          </FormRow>
        </>
      );
    },
    RCTitle: () => {
      return (
        <>
          <FormRow>
            <TextInput source="content.title" label="Заголовок" validate={validates.RCTitle} />
          </FormRow>
          <FormRow>
            <TextInput
              source="content.text"
              label="Текст"
              // validate={validates.RCTitleText}
              multiline
              rows={6}
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Наличие кнопки" source="content.hasButton" />
          </FormRow>
          <FormRow>
            <TextInput source="content.textButton" label="Текст кнопки" />
          </FormRow>
        </>
      );
    },
    RCMain: (props) => {
      return (
        <>
          <FormRow>
            <TextInput source="content.title" label="Заголовок" validate={validates.RCMainTitle} />
          </FormRow>
          <ImageInputWithFormRow
            {...props}
            source="content.photo"
            validate={validates.required}
            buttonText="Фото"
          />
          <FormRow>
            <BooleanInput label="Наличие кнопки" source="content.hasButton" />
          </FormRow>
          <FormRow>
            <TextInput source="content.textButton" label="Текст кнопки" />
          </FormRow>
          <FormRow>
            <ArrayInput source="content.subTitles" label="Подзаголовоки">
              <SimpleFormIterator>
                <TextInput source="text" label="Текст" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormRow>
        </>
      );
    },
    RCMainSlider: () => {
      return (
        <>
          <FormRow>
            <ArrayInput source="content.items" label="Элементы слайдера">
              <SimpleFormIterator>
                <TextInput source="title" label="Заголовок" validate={validates.RCMainTitle} />
                <TextInput source="text" label="Текст" />
                <ImageInput source="photo" validate={validates.required} buttonText="Фото" />
                <BooleanInput label="Наличие кнопки" source="hasButton" />
                <TextInput source="textButton" label="Текст кнопки" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormRow>
        </>
      );
    },
    RCLayouts: () => {
      return (
        <>
          <FormRow>
            <TextInput source="content.title" label="Заголовок" />
          </FormRow>
          <FormRow>
            <ArrayInput source="content.items" label="Элементы слайдера">
              <SimpleFormIterator>
                <ImageInput source="photo" buttonText="Фото" validate={validates.required} />
                <TextInput source="text" label="Текст" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormRow>
        </>
      );
    },
    RCPresentation: (props) => {
      return (
        <>
          <FormRow>
            <TextInput source="content.text" label="Текст" validate={validates.required} />
          </FormRow>
          <ImageInputWithFormRow
            {...props}
            source="content.photo"
            validate={validates.required}
            buttonText="Фото"
          />
          <FormRow>
            <TextInput
              source="content.link"
              label="Внешняя ссылка"
              helperText="Приоритет у ссылки выше"
            />
          </FormRow>
          <ImageInputWithFormRow {...props} source="content.presentation" isFile />
        </>
      );
    },
    RCImagesSlider: () => {
      return (
        <>
          <FormRow>
            <TextInput source="content.title" label="Заголовок" />
          </FormRow>
          <FormRow>
            <ArrayInput source="content.items" label="Элементы слайдера">
              <SimpleFormIterator>
                <ImageInput source="photo" buttonText="Фото" validate={validates.required} />
                <TextInput source="name" label="Название картинки" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormRow>
        </>
      );
    },
    RCVideo: (props) => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.link"
              label="Внешняя ссылка"
              helperText="Приоритет у ссылки выше"
            />
          </FormRow>
          <ImageInputWithFormRow {...props} source="content.photo" buttonText="Фото" />
          <ImageInputWithFormRow
            {...props}
            source="content.video"
            validate={validates.required}
            buttonText="Видео"
            isVideo
          />
        </>
      );
    },
    RCImages: () => {
      return (
        <>
          <FormRow>
            <BooleanInput label="Наличие кнопки" source="content.hasButton" />
          </FormRow>
          <FormRow>
            <TextInput source="content.textButton" label="Текст кнопки" />
          </FormRow>
          <FormRow>
            <ArrayInput source="content.items" label="Элементы слайдера">
              <SimpleFormIterator>
                <ImageInput source="photo" buttonText="Фото" validate={validates.required} />
                <TextInput source="name" label="Название картинки" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormRow>
        </>
      );
    },
    RCObjects: () => {
      return (
        <>
          <FormRow>
            <TextInput source="content.title" label="Заголовок" />
          </FormRow>
          <FormRow>
            <ArrayInput source="content.items" label="Элементы слайдера">
              <SimpleFormIterator>
                <TextInput source="text" label="Текст" validate={validates.required} />
                <ImageInput source="photo" buttonText="Фото" validate={validates.required} />
                <TextInput source="additionalText1" label="Дополнительный текст 1" />
                <TextInput source="additionalText2" label="Дополнительный текст 2" />
                <TextInput source="additionalText3" label="Дополнительный текст 3" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormRow>
        </>
      );
    },
    RCMap: () => {
      return (
        <>
          <FormRow>
            <TextInput
              source="content.link"
              label="Ссылка для карты"
              validate={validates.required}
            />
          </FormRow>
        </>
      );
    },
    RCMetrics: () => {
      return (
        <>
          <FormRow>
            <TextInput source="content.code" label="Код" multiline rows={6} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="content.position"
              label="Позиция"
              helperText="Куда вставлять на сайте"
              objectChoices={metricPositions}
              defaultValue={100}
            />
          </FormRow>
        </>
      );
    },
  },
};

export const defaultVisibleLists = {
  objects: false,
  villages: false,
  infrastructures: false,
  images: false,
  videos: false,
  peoples: false,
  experts: false,
};

export const widgetTemplatesById = {
  1: 'newsSubscription',
  2: 'newsTitle1',
  3: 'horizontalPhoto',
  4: 'verticalPhoto',
  5: 'squarePhoto',
  6: 'fourPhotos',
  7: 'text1',
  8: 'quote',
  9: 'video2',
  10: 'doubleVerticalPhoto',
  11: 'quote2',
  12: 'objectsSet1',
  13: 'objectsSet2',
  14: 'video1',
  15: 'object',
  16: 'oldArticle',
  17: 'villages',
  18: 'experts',
  20: 'RCTitle',
  21: 'RCMain',
  22: 'RCMainSlider',
  23: 'RCLayouts',
  24: 'RCPresentation',
  25: 'RCImagesSlider',
  26: 'RCVideo',
  27: 'RCImages',
  31: 'RCMap',
  32: 'RCObjects',
  33: 'RCMetrics',
};

const project = 'magazine';

export const FormCMSWidgets = (props) => {
  const { id, record } = props;

  const [widgetTemplateId, setWidgetTemplateId] = useState(record?.cmsWidgetTemplate?.id);

  const widgetTemplate = useMemo(() => {
    if (widgetTemplateId) {
      return widgetTemplatesById[widgetTemplateId];
    }
  }, [widgetTemplateId]);

  const visibleLists = useMemo(() => {
    const currentVisibleLists = { ...defaultVisibleLists };

    if (
      widgetTemplate === 'objectsSet1' ||
      widgetTemplate === 'objectsSet2' ||
      widgetTemplate === 'object'
    ) {
      currentVisibleLists.objects = true;
    }

    if (widgetTemplate === 'villages') {
      currentVisibleLists.villages = true;
    }

    if (widgetTemplate === 'experts') {
      currentVisibleLists.experts = true;
    }

    if (widgetTemplate === 'quote2') {
      currentVisibleLists.peoples = true;
    }

    return currentVisibleLists;
  }, [widgetTemplate]);

  const content = useMemo(() => {
    if (widgetTemplate) {
      return widgetTemplates[project][widgetTemplate](props);
    }
  }, [props, widgetTemplate]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <BooleanInput label="Показывать в меню" source="anyJsonDictionary.visibilityInMenu" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Общий компонент" source="isShared" />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="cmsWidgetTemplate"
            resourceName="cmsAdmin/widgetTemplates"
            resourceId="cmsWidgetTemplateId"
            componentProps={{
              label: 'Шаблон виджета',
            }}
            onChange={(value) => {
              setWidgetTemplateId(value?.id);
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="widgetTags"
            resourceName="cmsAdmin/widgetTags"
            resourceId="cmsWidgetTagId"
            additionalSource="cmsWidgetTag"
            componentProps={{
              label: 'Тэги виджетов',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
        </FormCard>
      </FormTab>
      <FormTab label="Страницы">
        <ListCMSPages />
      </FormTab>
      {widgetTemplate && (
        <FormTab label="Контент">
          <FormCard>{content}</FormCard>
        </FormTab>
      )}
      {visibleLists.images && (
        <FormTab label="Изображения">
          <ListImagesOrVideos id={id} hasMultiple hasTemplateName />
        </FormTab>
      )}
      {visibleLists.videos && (
        <FormTab label="Видео">
          <ListImagesOrVideos id={id} isVideo />
        </FormTab>
      )}
      {visibleLists.infrastructures && (
        <FormTab label="Инфраструктура">
          <ListInfrastructures />
        </FormTab>
      )}
      {visibleLists.objects && (
        <FormTab label="Объекты">
          <ListObjects />
        </FormTab>
      )}
      {visibleLists.villages && (
        <FormTab label="Комплексы">
          <ListVillages />
        </FormTab>
      )}
      {visibleLists.peoples && (
        <FormTab label="Люди">
          <ListPeoples />
        </FormTab>
      )}
      {visibleLists.experts && (
        <FormTab label="Эксперты">
          <ListPeoples onlyExperts />
        </FormTab>
      )}
    </TabbedForm>
  );
};
