import React, { useMemo } from 'react';
import { FormTab, FormDataConsumer } from 'react-admin';
import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm, BooleanInput, NumberInput } from '../../common';

import { FormRow } from '../../FormRow';

import {
  ImageInputWithFormRow,
  CheckboxWithFormRow,
  CommentsTab,
  TasksTab,
  ConstantSelect,
  List,
  AutocompleteWithFormRow,
} from '../../app';
import { checkRoleAdmin, checkRoleAdminOrSupervisor, validates } from '../../../utils';

import { currentProfile } from '../../../authProvider';
import { DynamicList } from '../../additionalLists';
import { ownerStatuses, publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';
import { DateTimePickerInput } from '../../RAInputs';
import { dataProvider } from '../../../dataProvider';

const objectOptionsByRole = {
  owner: {
    id: 1,
    role: 'isOwner',
    filter: 'OwnerId',
    text: 'Объекты собственника',
  },
  expert: {
    id: 2,
    role: 'isExpert',
    filter: 'ManagerId',
    text: 'Объекты эксперта',
  },
  representative: {
    id: 3,
    role: 'isRepresentative',
    filter: 'RepresentativeId',
    text: 'Объекты представителя',
  },
};

export const FormUsers = (props) => {
  const { permissions, id, record, location } = props;

  const hasRoleAdmin = useMemo(() => {
    return checkRoleAdmin(permissions);
  }, [permissions]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const disableEdit = useMemo(() => {
    const isCurrentUser = currentProfile.id === record.authId;
    if (
      hasRoleAdminOrSupervisor ||
      !id ||
      isCurrentUser ||
      record.isOwner ||
      record.isRepresentative
    ) {
      return false;
    }

    return true;
  }, [hasRoleAdminOrSupervisor, id, record]);

  const elementsObjectsByRole = useMemo(() => {
    return Object.values(objectOptionsByRole).map((objectOption) => {
      if (!record[objectOption.role]) {
        return null;
      }

      return (
        <FormTab label={objectOption.text} key={objectOption.id}>
          <DynamicList
            fetchOptions={{
              resource: 'RealtyObjects',
              filter: { [objectOption.filter]: `${id}` },
            }}
            contentOptions={{
              type: 'object',
              hasButtons: false,
            }}
            contentElement={List}
          />
        </FormTab>
      );
    });
  }, [id, record]);

  return (
    <TabbedForm {...props} hideSave={disableEdit}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <ImageInputWithFormRow {...props} source="photo" />
          {hasRoleAdmin && <CheckboxWithFormRow source="isStaff" label="Сотрудник Villagio" />}
          <FormRow>
            <TextInput source="name" label="Имя" validate={validates.peopleName} />
          </FormRow>
          <FormRow>
            <TextInput source="middleName" label="Отчество" />
          </FormRow>
          <FormRow>
            <TextInput source="lastName" label="Фамилия" />
          </FormRow>
          {hasRoleAdmin && (
            <FormRow>
              <TextInput source="authId" label="Id авторизации" />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="eMail" label="Эл. почта" />
          </FormRow>
          <FormRow>
            <TextInput
              validate={validates.phone}
              source="phone"
              label="Телефоны"
              helperText="Вводить по маске +7(999)888-77-66. Можно ввести несколько: +7(999)888-77-66, +7(999)888-77-66, +7(999)888-77-66"
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                formData.isStaff &&
                formData.isExpert && (
                  <AutocompleteWithFormRow
                    {...props}
                    source="salesDirection"
                    resourceName="SalesDirections"
                    resourceId="salesDirectionId"
                    validate={validates.required}
                    componentProps={{
                      label: 'Направление',
                    }}
                  />
                )
              );
            }}
          </FormDataConsumer>
          <AutocompleteWithFormRow
            {...props}
            source="userActivityType"
            resourceName="userActivityTypes"
            resourceId="userActivityTypeId"
            componentProps={{
              label: 'Род деятельности',
              requestAdd: (value) => {
                return dataProvider.create('UserActivityTypes', {
                  data: { name: value, sortOrder: 1000, sysStatus: 200 },
                });
              },
              getOptionLabel: (option) => {
                if (!option) {
                  return '';
                }

                return `${option.id}: ${option.name || ''}`;
              },
              minSearchLenght: 1,
            }}
          />
          {/* <FormRow>
          <TextInput
            validate={validates.phone}
            source="phoneMore"
            label="Дополнительный телефон"
            helperText="Вводить по маске 79998887766"
          />
        </FormRow> */}
          <FormRow>
            <DateTimePickerInput pickerType="date" source="birthDate" label="День рождения" />
          </FormRow>
          <FormRow>
            <TextInput source="telegramId" label="Ссылка на Телеграм" />
          </FormRow>
          <FormRow>
            <TextInput source="carPlates" label="Номера машин" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
              disabled={!hasRoleAdmin}
            />
          </FormRow>
          <AutocompleteWithFormRow
            source="clientTags"
            resourceName="clientTags"
            resourceId="clientTagId"
            additionalSource="clientTag"
            helperText="Справочник - клиентские тэги"
            {...props}
            componentProps={{
              label: 'Тэги',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormDataConsumer>
            {({ formData }) => {
              const isOwner = formData.isOwner;
              if (!isOwner) {
                delete formData.ownerStatus;
              }

              return (
                isOwner && (
                  <FormRow>
                    <ConstantSelect
                      source="ownerStatus"
                      objectChoices={ownerStatuses}
                      label="Статус собственника"
                      allowEmpty
                    />
                  </FormRow>
                )
              );
            }}
          </FormDataConsumer>
          {hasRoleAdminOrSupervisor && (
            <>
              <FormDataConsumer>
                {({ formData }) => {
                  const isExpertOrManager = formData.isManager || formData.isExpert;
                  if (!isExpertOrManager) {
                    delete formData.eliteDealsNum;
                    delete formData.bizDealsNum;
                  }

                  return (
                    isExpertOrManager && (
                      <>
                        <FormRow>
                          <NumberInput
                            min={0}
                            label="Количество сделок (элитка)"
                            source="eliteDealsNum"
                          />
                        </FormRow>
                        <FormRow>
                          <NumberInput
                            min={0}
                            label="Количество сделок (бизнес)"
                            source="bizDealsNum"
                          />
                        </FormRow>
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) => {
                  const isEmployee =
                    formData.isManager ||
                    formData.isExpert ||
                    formData.isSupervisor ||
                    formData.isAdmin;
                  if (!isEmployee) {
                    delete formData.position;
                    delete formData.yearsInService;
                  }

                  return (
                    isEmployee && (
                      <>
                        <FormRow>
                          <TextInput source="position" label="Должность" />
                        </FormRow>
                        <FormRow>
                          <NumberInput min={0} label="Лет в компании" source="yearsInService" />
                        </FormRow>
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
            </>
          )}
          <FormRow maxWidth={800}>
            <TextInput source="comment" label="Комментарий" multiline rows={6} />
          </FormRow>
          {hasRoleAdmin && (
            <>
              <FormRow>
                <BooleanInput label="Новый сотрудник" source="isNew" />
              </FormRow>
              <FormRow>
                <BooleanInput label="Подписан на рассылку" source="isSubscribed" />
              </FormRow>
              <FormRow>
                <NumberInput min={0} label="Id сотрудника в комеджике" source="comagicId" />
              </FormRow>
              <FormDataConsumer>
                {({ formData }) => {
                  const isExpertOrManager = formData.isManager || formData.isExpert;
                  if (!isExpertOrManager) {
                    delete formData.isGodExpert;
                  }

                  return (
                    isExpertOrManager && (
                      <FormRow>
                        <BooleanInput label="Ведущий эксперт" source="isGodExpert" />
                      </FormRow>
                    )
                  );
                }}
              </FormDataConsumer>
            </>
          )}
          <FormTitle>Роли</FormTitle>
          <FormRow maxWidth={800} isLast>
            {hasRoleAdmin && (
              <>
                <FormRow>
                  <BooleanInput label="Эксперт" source="isExpert" />
                </FormRow>
                <FormRow>
                  <BooleanInput label="Сервисный эксперт" source="isServiceManager" />
                </FormRow>
                <FormRow>
                  <BooleanInput label="Администратор" source="isAdmin" />
                </FormRow>
                <FormRow>
                  <BooleanInput label="Супервайзер" source="isSupervisor" />
                </FormRow>
                <FormRow>
                  <BooleanInput label="Менеджер" source="isManager" />
                </FormRow>
                <FormRow>
                  <BooleanInput label="ОДП" source="isOdp" />
                </FormRow>
              </>
            )}
            <FormRow>
              <BooleanInput label="Клиент" source="isClient" />
            </FormRow>
            <FormRow>
              <BooleanInput label="Собственник" source="isOwner" />
            </FormRow>
            <FormRow>
              <BooleanInput label="Представитель" source="isRepresentative" />
            </FormRow>
            <FormRow>
              <BooleanInput label="Житель" source="isResident" />
            </FormRow>
            <FormRow>
              <BooleanInput label="Гость" source="isGuest" />
            </FormRow>
          </FormRow>
        </FormCard>
      </FormTab>
      {id && (
        <FormTab label="Комментарии">
          <CommentsTab id={id} type={300} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={600} location={location} />
        </FormTab>
      )}
      {id && elementsObjectsByRole}
    </TabbedForm>
  );
};
