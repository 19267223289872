import React, { useMemo, useEffect, useReducer, useRef, useCallback, useState } from 'react';
import { Collapse, useTheme, makeStyles } from '@material-ui/core';
import { useRefresh } from 'react-admin';
import {
  contractStatuses,
  contractStatusesForBuy,
  contractStatusesForSell,
  contractTypes,
  luxuryClasses,
} from '../../../../constants';
import { getFormattedDateAndTime, getFullNameWithId, isObject } from '../../../../utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { dataProvider } from '../../../../dataProvider';
import { ListItemHeader } from '../ItemHeader';
import { ConstantSelectField } from '../../ConstantSelectField';
import { AutocompleteDynamic, Avatar, TextField } from '../../../common';
import { PeopleOption } from '../../../RASelects';
import { ListItemContent } from '../ItemContent';
import { ListItem } from '../Item';
import { Comments } from '../../Comments';
import {
  AddCommentDialogButton,
  CreateShowDialogButton,
  CreateTaskDialogButton,
} from '../../../buttons';

const reducer = (state, action) => {
  return { ...state, [action.type]: action.payload };
};

const useNewStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  buttonsRow: {
    width: '32%',
    boxSizing: 'border-box',
    paddingTop: '16px',
    flex: '0 0 auto',

    [theme.breakpoints.down('1024')]: {
      width: '49%',
    },

    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
  buttonsAuto: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonsAutoRow: {
    boxSizing: 'border-box',
    paddingTop: '16px',
    flex: '0 0 auto',
    marginRight: '16px',
  },
  inputAvatar: {
    width: '24px',
    height: '24px',
    marginRight: '2px',
    fontSize: '10px',
  },
}));

export const convertToMillions = (number) => {
  return Math.floor((number / 1000000) * 100) / 100;
};

export const NewListItemContract = ({
  record,
  hasButtons,
  hasCheckbox,
  hasCollapse,
  onChangeBgColor,
  headerProps,
}) => {
  const classes = useNewStyles();
  const { id } = record;
  const { expanded } = headerProps;

  let name = id;

  if (record.name) {
    name += ` | ${record.name}`;
  }

  if (record.createdDate) {
    name += ` | ${getFormattedDateAndTime(record.createdDate)}`;
  }

  const link = `/Contracts/${record.id}`;

  const tags = [];
  const isBuyAndRent = record.type === 100 || record.type === 300 || !record.type;

  if (contractTypes[record.type]) {
    tags.push(contractTypes[record.type]);
  }

  const status = parseInt(record.status, 10);
  const text = contractStatuses[status];
  let statusColor = 'default';

  if (status === 10000) {
    statusColor = 'green';
  }

  if (status === 20050 || status === 20000 || status === 20100 || status === 20200) {
    statusColor = 'red';
  }

  tags.push({
    text,
    color: statusColor,
  });

  if (record.isMovedToSP) {
    tags.push('SP');
  }

  if (record.isMovedToSupport) {
    tags.push('ОС');
  }

  if (record.realtyObject?.friendlyId) {
    tags.push(record.realtyObject.friendlyId);
  }

  if (record.realtyObjectType) {
    tags.push(record.realtyObjectType.name);
  }

  if (isBuyAndRent && record.budget) {
    tags.push(`Бюджет до ${convertToMillions(record.budget)} млн.`);
  } else if (!isBuyAndRent && record.budget) {
    tags.push(`Цена от ${convertToMillions(record.budget)} млн.`);
  }

  if (record.realtyObjectType) {
    tags.push(record.realtyObjectType.name);
  }

  if (record.luxuryClass) {
    tags.push(luxuryClasses[record.luxuryClass]);
  }

  if (record?.contractTags?.length > 0) {
    record?.contractTags.forEach((contractTag) => {
      if (contractTag?.contractTag?.name) {
        tags.push(contractTag.contractTag.name);
      }
    });
  }

  const theme = useTheme();

  const color = useMemo(() => {
    if (status === 10000) {
      return theme.palette.green['background'];
    }

    if (status === 20050 || status === 20000 || status === 20100 || status === 20200) {
      return theme.palette.red['background'];
    }
  }, [status, theme.palette.green, theme.palette.red]);

  useEffect(() => {
    onChangeBgColor(color);
  }, [color, onChangeBgColor]);

  const [inputValues, dispatch] = useReducer(reducer, {
    firstExpertId: record?.firstExpertId,
    firstExpert: record?.firstExpert,
    currentExpertId: record?.currentExpertId,
    currentExpert: record?.currentExpert,
    comment: record?.comment || '',
    status: record?.status || '',
  });

  const refresh = useRefresh();

  const { clearTimeoutDebounce, timeoutDebounce } = useDebounce();

  const hasChangesRef = useRef(false);

  const onChangeInputValues = useCallback((newValue, event) => {
    const resultValue = newValue;
    const fieldName = isObject(event) ? event.target.name : event;
    if (isObject(resultValue)) {
      dispatch({ type: `${fieldName}Id`, payload: resultValue.id });
    }
    dispatch({ type: fieldName, payload: resultValue });
    hasChangesRef.current = true;
  }, []);

  const onBlurInputValues = useCallback(() => {
    if (hasChangesRef.current) {
      const fetch = async () => {
        await dataProvider.update('Contracts', {
          id: record.id,
          data: {
            ...record,
            ...inputValues,
          },
          previousData: record,
        });

        refresh();
      };

      timeoutDebounce(() => {
        fetch();
        hasChangesRef.current = false;
      });
    }
  }, [inputValues, record, refresh, timeoutDebounce]);

  const onFocusInputValues = useCallback(() => {
    clearTimeoutDebounce();
  }, [clearTimeoutDebounce]);

  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(true);

  const loadComment = useCallback(() => {
    const fetch = async () => {
      setCommentsLoading(true);
      try {
        const result = await dataProvider.getList('Comments', {
          pagination: { perPage: 1, page: 1 },
          filter: { CommentObjectId: record.id, CommentObjectType: 500 },
        });
        setComments(result.data);
      } catch (error) {
        console.error(error);
      } finally {
        setCommentsLoading(false);
      }
    };

    fetch();
  }, [record.id]);

  useEffect(() => {
    if (!expanded) {
      setComments([]);
      return;
    }

    loadComment();
  }, [expanded, loadComment]);

  const handleReloadComment = useCallback(() => {
    loadComment();
  }, [loadComment]);

  const commentElements = useMemo(() => {
    return (
      <>
        {comments.length > 0 ? (
          <Comments items={comments} onReload={handleReloadComment} isCard />
        ) : (
          'Нет комментариев'
        )}
        {/* <AddCommentDialogButton
          size="small"
          type={500}
          id={record.id}
          onReload={handleReloadComment}
        /> */}
      </>
    );
  }, [comments, handleReloadComment]);

  const [show, setShow] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const loadShow = useCallback(() => {
    const fetch = async () => {
      setShowLoading(true);
      try {
        const result = await dataProvider.getList('Demos', {
          pagination: { perPage: 1, page: 1 },
          filter: { ContractId: record.id, SortBy: 401 },
        });
        setShow(result.data?.[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setShowLoading(false);
      }
    };

    fetch();
  }, [record.id]);

  useEffect(() => {
    if (!expanded) {
      setShow();
      return;
    }

    loadShow();
  }, [expanded, loadShow]);

  const handleReloadShow = useCallback(() => {
    loadShow();
  }, [loadShow]);

  const showElements = useMemo(() => {
    return (
      <>
        {show ? <ListItem type="show" record={show} isNewItem hasButtons={false} /> : 'Нет показов'}
        {/* <CreateShowDialogButton defaultContract={record} onReload={handleReloadShow} /> */}
      </>
    );
  }, [show]);

  const [task, setTask] = useState([]);
  const [taskLoading, setTaskLoading] = useState(true);

  const loadTask = useCallback(() => {
    const fetch = async () => {
      setTaskLoading(true);
      try {
        const result = await dataProvider.getList('Taasks/ForObject', {
          pagination: { perPage: 1, page: 1 },
          filter: { taaskObjectId: record.id, taaskObjectType: 700, SortBy: 401 },
        });
        setTask(result.data?.[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setTaskLoading(false);
      }
    };

    fetch();
  }, [record.id]);

  useEffect(() => {
    if (!expanded) {
      setTask();
      return;
    }

    loadTask();
  }, [expanded, loadTask]);

  const handleReloadTask = useCallback(() => {
    loadTask();
  }, [loadTask]);

  const taskElements = useMemo(() => {
    return (
      <>
        {task ? (
          <ListItem
            type="task"
            record={task}
            isNewItem
            hasButtons={false}
            depthKey="taask"
            onReload={handleReloadTask}
          />
        ) : (
          'Нет задач'
        )}
        {/* <CreateTaskDialogButton type={700} defaultItem={record} onReload={handleReloadTask} /> */}
      </>
    );
  }, [handleReloadTask, task]);

  return (
    <>
      <ListItemHeader
        record={record}
        link={link}
        name={name}
        hasButtons={hasButtons}
        tags={tags}
        hasTaskStatus
        tagName="Лид"
        hasCheckbox={hasCheckbox}
        hasCollapse={hasCollapse}
        {...headerProps}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.buttonsAuto}>
          <div className={classes.buttonsAutoRow}>
            <CreateTaskDialogButton type={700} defaultItem={record} onReload={handleReloadTask} />
          </div>
          <div className={classes.buttonsAutoRow}>
            <CreateShowDialogButton defaultContract={record} onReload={handleReloadShow} />
          </div>
          <div className={classes.buttonsAutoRow}>
            <AddCommentDialogButton
              size="small"
              type={500}
              id={record.id}
              onReload={handleReloadComment}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={isBuyAndRent ? contractStatusesForBuy : contractStatusesForSell}
              size="small"
              value={inputValues.status}
              onChange={onChangeInputValues}
              name="status"
              label="Этап воронки"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              allowEmpty
            />
          </div>
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="Peoples"
              fullDynamic="AllNames"
              label="Эксперт принял лид"
              name="firstExpert"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.firstExpert}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`)}
              renderOption={(option) => <PeopleOption option={option} />}
              startAdornment={(value) => {
                return (
                  value && <Avatar option={value} source="photo" className={classes.inputAvatar} />
                );
              }}
              providerParams={{ filter: { isExpert: true } }}
            />
          </div>
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="Peoples"
              fullDynamic="AllNames"
              label="Эксперт текущий"
              name="currentExpert"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.currentExpert}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`)}
              renderOption={(option) => <PeopleOption option={option} />}
              startAdornment={(value) => {
                return (
                  value && <Avatar option={value} source="photo" className={classes.inputAvatar} />
                );
              }}
              providerParams={{ filter: { isExpert: true } }}
            />
          </div>
        </div>
        <ListItemContent>
          <TextField
            multiline
            rows={4}
            label="Комментарий"
            size="small"
            onBlur={onBlurInputValues}
            onFocus={onFocusInputValues}
            name="comment"
            onChange={onChangeInputValues}
            value={inputValues.comment}
            isControlled
          />
        </ListItemContent>
        {record?.mainClient ? (
          <ListItemContent>
            <ListItem type="client" record={record.mainClient} isNewItem hasButtons={false} />
          </ListItemContent>
        ) : (
          <ListItemContent>Нет клиентов</ListItemContent>
        )}
        {record?.clientTouch ? (
          <ListItemContent>
            <ListItem type="touch" record={record.clientTouch} isNewItem hasButtons={false} />
          </ListItemContent>
        ) : (
          <ListItemContent>Нет касаний</ListItemContent>
        )}
        <ListItemContent loading={taskLoading}>{taskElements}</ListItemContent>
        <ListItemContent loading={showLoading}>{showElements}</ListItemContent>
        <ListItemContent loading={commentsLoading}>{commentElements}</ListItemContent>
      </Collapse>
    </>
  );
};
